import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { multi } from '../../mock-chart';
import { DatePipe } from '@angular/common';

import { TranslateService } from '@ngx-translate/core';
import {ApiService} from '../../services/api.service';
import {LoadingComponent} from '../../shared/dialogs/loading/loading.component';
import {NotificationComponent} from '../../shared/dialogs/notification/notification.component';
import * as moment from 'moment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: [ './dashboard.component.scss' ]
})
export class DashboardComponent implements OnInit, AfterViewInit {

  details: any;
  tasks: any;
  pendTasks: any;
  multi: any[];
  view: any[] = [];

  // options
  taskChart = [
    {
      name: 'Cargas',
      series: []
    },
    {
      name: 'Descargas',
      series: []
    }
  ];
  legend = true;
  legendTitle = 'Legenda'
  showLabels = true;
  animations = true;
  xAxis = true;
  yAxis = true;
  showYAxisLabel = true;
  showXAxisLabel = true;
  xAxisLabel = 'Data';
  yAxisLabel = 'Tarefas';
  timeline = true;
  user: any;
  driver: any;

  colorScheme = {
    domain: [ '#E44D25','#5AA454', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };

  constructor(private apiService: ApiService,
              private router: Router,
              private loading: LoadingComponent,
              private notification: NotificationComponent,
              private translate: TranslateService) {
    //Object.assign(this, { multi });
  }

  ngOnInit(): void {
    this.getPendingTasks();
    this.getDriverDetails();
    //this.getTasks();
    //this.checkLogin();

    this.translate.onLangChange.subscribe((lang: any) => {
      this.xAxisLabel = this.translate.instant('dashboard.date');
      this.yAxisLabel = 'Tarefas';
      this.legendTitle = this.translate.instant('dashboard.legend');
    });

    this.user = localStorage.getItem('user')
  }

  ngAfterViewInit(): void {
    this.loading.hide();
  }

  onNavigate(url) : void {
      this.router.navigate([url.replace('menu-','/')]);
      const menu = (<HTMLInputElement>document.querySelector('.sidebar'));
      if(menu) {
        const menuItems = menu.querySelectorAll('ui5-side-navigation-item');
        if(menuItems) menuItems.forEach((item) => {
          if(item.classList[0] != url) item['selected'] = false;
        });
      }
  }

  onNavigateTask(task) : void {
    this.router.navigate(['tasks']);
    //this.router.navigate(['tasks',task]);
    const menu = (<HTMLInputElement>document.querySelector('.sidebar'));
    if(menu) {
      const menuItems = menu.querySelectorAll('ui5-side-navigation-item');
      if(menuItems) menuItems.forEach((item) => {
        if(item.classList[0] != 'menu-task') item['selected'] = false;
      });
    }
  }

  onSelect(data): void {
    //console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    //console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    //console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  xAxisTickFormatting(value): string {
    const date = new Date(value);
    const datePipe = new DatePipe('en-UK');
    return datePipe.transform(date, 'mediumDate');
  }

  getWidth(): number {
    return window.innerWidth;
  }

  checkLogin(): void {
    this.loading.show();
    this.apiService.checkLogin(moment().format('YYYY-MM-DD'))
      .subscribe(data => {
        // @ts-ignore
        this.carPlate = data.CarPlate;
      },error => {
        this.loading.hide();
        this.notification.show('Error',JSON.stringify(error['error']));
        console.log(error.error);
      },() => {
        this.loading.hide();
      });
  }

  getDriverDetails(): void {
    this.loading.show();
    this.apiService.getDashDriverDetails(moment().format('YYYY-MM-DD'))
      .subscribe(data => {
        this.details = data;
        // @ts-ignore
        //this.carPlate = data.CarPlate;
      },error => {
        this.loading.hide();
        this.notification.show('Error',JSON.stringify(error['error']));
        console.log(error.error);
      },() => {
        this.loading.hide();
      });
  }

  getPendingTasks(): void {
    this.loading.show();
    this.apiService.getPendingTasks()
      .subscribe(data => {
        this.tasks = data;
        this.pendTasks = [];
        // @ts-ignore
        if(data?.length > 0) {
          // @ts-ignore
          for (let i = 0; i < data.length; i++) {
            if(data[i].Status !== 'End') {
              this.pendTasks.push(data[i]);
            }
            if(data[i].needCarga == 'Y') {
              if(data[i]?.PrevDateCar) {
                let obj = {
                  value: 1,
                  name: data[i].PrevDateCar
                }
                let existingObj = null;
                try {
                  existingObj = this.taskChart[0].series.find(
                    o => moment(o.name).format('YYYY-MM-DD') === moment(data[i].PrevDateCar).format('YYYY-MM-DD'));
                  existingObj.value++;
                } catch (e) {
                  //console.log(e);
                }
                if(!existingObj) {
                  this.taskChart[0].series.push(obj);
                }
              }

            } else {
              if(data[i]?.PrevDateDesc) {
                let obj = {
                  value: 1,
                  name: data[i].PrevDateDesc
                }
                let existingObj = null;
                try {
                  existingObj = this.taskChart[1].series.find(
                    o => moment(o.name).format('YYYY-MM-DD') === moment(data[i].PrevDateDesc).format('YYYY-MM-DD'));
                  existingObj.value++;
                } catch (e) {
                  //console.log(e);
                }
                if(!existingObj) {
                  this.taskChart[1].series.push(obj);
                }
              }
            }

          }
          this.taskChart = [...this.taskChart];
          try{
            for (let tc = 0; tc < this.taskChart.length; tc++) {
              if(this.taskChart[tc]['series']) {
                for (let t = 0; t < this.taskChart[tc]['series'].length; t++) {
                  this.taskChart[tc]['series'][t]['name'] = this.taskChart[tc]['series'][t]['name'].split(' ')[0];
                }
              }
            }
          } catch (e) {
            //console.log(e);
          }
          this.showPointsChart();
        }
      },error => {
        this.loading.hide();
        this.notification.show('Error',JSON.stringify(error['error']));
        console.log(error.error);
      },() => {
        this.loading.hide();
      });
  }

  showPointsChart(): void {
    if(this.taskChart[0].series.length == 1) {
      // @ts-ignore
      const el = <HTMLInputElement>document.querySelectorAll('g.line-series path');
      if(el && el[0]) {
        el[0].setAttribute('stroke-width', '10');
        el[0].setAttribute('stroke-linecap', 'round');
      }
    }
    if(this.taskChart[1].series.length == 1) {
      // @ts-ignore
      const el = <HTMLInputElement>document.querySelectorAll('g.line-series path');
      if(el && el[1]) {
        el[1].setAttribute('stroke-width', '10');
        el[1].setAttribute('stroke-linecap', 'round');
      }
    }
  }

}
