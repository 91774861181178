// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  //appVersion: require('../../package.json').version,
  appVersion: '1.3.8-prod',
  production: false,
  authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
  chatKey: 'U2FsdGVkX18LxY2Rv3O4+xGDeRQ76aNNvxHRi1lXz1E=',
  keytoDecrypt: '@KEYTO#DECR',
  //database: 'SBO_TESTESVALADO', //Tests
  database: 'SBO_VALADO', //Production
  //servicePath: 'bTransportsSrvTestes', //Tests
  servicePath: 'bTransportsSrv', //Production
  //apiUrl: 'http://localhost:4000', //Tests
  apiUrl: '', //Production
  //apiUrl: 'http://localhost:8080/proxy/tdv2.php', //Tests
  //apiUrl: '/api/tdv.php', //Production
  //apiUrl: 'http://driversdev.tdv-group.com', //Tests
  //chatUrl: 'chat.newoxygen.com', //Tests
  chatUrl: 'chat.tdv-group.com', // Production
  chatPort: '4443' // Production
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
