import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';
@Injectable() export class AlertService {
  private subject = new Subject<any>();
  constructor(){}
  confirm(message: string,yesFn:()=>void,noFn:()=>void){
    this.setConfirmation(message,yesFn,noFn);
    this.show('',message)
  }
  setConfirmation(message: string,yesFn:()=>void,noFn:()=>void) {
    let that = this;
    this.subject.next({ type: "confirm",
      text: message,
      yesFn:
        function(){
          that.subject.next();
          that.hide();
          yesFn();
        },
      noFn:function(){
        that.subject.next();
        that.hide();
        noFn();
      }
    });
  }

  show(type, value): void {
    console.log(type);
    const alertDialog = <HTMLInputElement>document.querySelector('#alertDialog');
    if(alertDialog) {
      alertDialog['content'][0].innerText = value;
      // @ts-ignore
      alertDialog.show();
    }
  }

  hide(): void {
    const alertDialog = <HTMLInputElement>document.querySelector('#alertDialog');
    if(alertDialog) {
      // @ts-ignore
      alertDialog.close();
    }
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
}
