import {Component, ElementRef, OnInit, AfterViewInit, ViewChild} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { addCustomCSS } from "@ui5/webcomponents-base/dist/Theming";

import { ApiService } from '../../services/api.service';
import { AlertService } from '../../services/alert.service';
import { GeocodingApiService } from '../../services/geocoding.service';
import { LoadingComponent } from '../../shared/dialogs/loading/loading.component';
import { NotificationComponent } from '../../shared/dialogs/notification/notification.component';
import { TranslateService } from '@ngx-translate/core';
import { SignaturePad } from 'angular2-signaturepad';
import * as moment from 'moment';
import {forkJoin} from 'rxjs';
addCustomCSS("ui5-tabcontainer", `.ui5-tc__header { box-shadow: none;
  border-bottom: var(--ui5-listitem-border-bottom); background-color: transparent; }
  .ui5-tc__content { padding: 0 !important; border-bottom: none; background-color: transparent; }
  .ui5-tc__contentItem { overflow: hidden; }
  .ui5-tc__headerList { margin-left: -15px; }
  :host(.hide-header) .ui5-tc__header { display: none; }` );
addCustomCSS("ui5-label",` :host(:not([wrap])) .ui5-label-root { white-space: normal; }`)

@Component({
  selector: 'app-task-detail',
  templateUrl: './task-detail.component.html',
  styleUrls: [ './task-detail.component.scss' ]
})
export class TaskDetailComponent implements OnInit, AfterViewInit {
  @ViewChild('dialogLoad') dialogLoadEl: ElementRef;
  @ViewChild('kmsLoad') kmsLoadEl: ElementRef;
  @ViewChild('dateLoad') dateLoadEl: ElementRef;
  @ViewChild('timeLoad') timeLoadEl: ElementRef;
  @ViewChild('dialogUnload') dialogUnloadEl: ElementRef;
  @ViewChild('kmsUnload') kmsUnloadEl: ElementRef;
  @ViewChild('dateUnload') dateUnloadEl: ElementRef;
  @ViewChild('timeUnload') timeUnloadEl: ElementRef;
  @ViewChild('unloadTime') unloadTimeEl: ElementRef;
  @ViewChild('destinationUnload') destinationUnloadEl: ElementRef;
  @ViewChild('dialogIncidence') dialogIncidenceEl: ElementRef;
  @ViewChild('motiveIncidence') motiveIncidenceEl: ElementRef;
  @ViewChild('dateIncidence') dateIncidenceEl: ElementRef;
  @ViewChild('timeIncidence') timeIncidenceEl: ElementRef;
  @ViewChild('dialogIncidences') dialogIncidencesEl: ElementRef;
  @ViewChild('dialogSign') dialogSignEl: ElementRef;
  @ViewChild('dialogFiles') dialogFilesEl: ElementRef;
  @ViewChild('dialogFilesInc') dialogFilesIncEl: ElementRef;
  @ViewChild('dialogFilesDispatches') dialogFilesDispatchesEl: ElementRef;
  @ViewChild('dialogPreview') dialogPreviewEl: ElementRef;
  @ViewChild('dialogMap') dialogMapEl: ElementRef;
  @ViewChild('dialogTrailerState') dialogTrailerStateEl: ElementRef;
  @ViewChild('strap') strapEl: ElementRef;
  @ViewChild('rulers') rulersEl: ElementRef;
  @ViewChild('typePay') typePayEl: ElementRef;
  @ViewChild('typePayDest') typePayDestEl: ElementRef;
  @ViewChild('fotosFile') fotosFileEl: ElementRef;
  @ViewChild('fotosCollection') fotosCollectionEl: ElementRef;
  @ViewChild('incFile') incFileEl: ElementRef;
  @ViewChild('filesCollection') filesCollectionEl: ElementRef;
  @ViewChild('tabCli') tabCliEl: ElementRef;
  @ViewChild('tabDes') tabDesEl: ElementRef;
  @ViewChild(SignaturePad) signaturePad: SignaturePad;


  rows = [];
  carPlate: string;
  carCode: string;
  trailerCode: string;
  task: any;
  taskType: string;
  taskDetail: any;
  taskDetailsC = [];
  taskDetailsD = [];
  incidences = [];
  incidenceTypes = [];
  OpenIncidences = [];
  oldWeight: any;
  oldQtd: any;
  codTask: any;
  configs: any;
  fotosCMR = [];
  filesIncidences = [];
  filesDispatches = [];
  isPdf: boolean;
  fileViewer: any;
  formLoad: FormGroup;
  formUnload: FormGroup;
  formIncidence: FormGroup;
  formTrailerState: FormGroup;
  signature: boolean;
  signatureImg: string;
  signaturePadOptions: Object = {
    'minWidth': 2,
    'canvasWidth': 700,
    'canvasHeight': 300
  };
  latitude: number;
  longitude: number;
  tabIndex: number;
  docRes: number;
  articleId: number;
  lineIdRSV2: number;
  tasksNumber: number = null;
  unloadAll: boolean;
  //loadDisabled: boolean;
  //unloadDisabled: boolean;
  unloaded: boolean;
  gmr: boolean;
  Pids: any;
  PdocEntry: any;
  PcarCode: string;
  PtrailerCode: string;
  PbaseType: string;
  PdeliveryType: string;
  Ptype: string;
  PloadUnload: string;
  PlineCounter: string;
  paymentTypes = [
    { id: '', value: '' },
    { id: 1, value: 'Numerário' },
    { id: 2, value: 'Cheque' },
    { id: 3, value: 'Transferência' }
  ];
  localChange = [
    { Code: "VAL", Name: "Valado do Frades" },
    { Code: "VAG", Name: "Vagos" },
    { Code: "CAR", Name: "Carregado" },
    { Code: "CTM", Name: "CT Pinto Madrid" },
    { Code: "BRS", Name: "British Steel" },
    { Code: "ALR", Name: "Algarve Removals" },
    { Code: "NOT", Name: 'Outro' }
  ];

  constructor(private loading: LoadingComponent,
    private notification: NotificationComponent,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private alertDialog: AlertService,
    private geocodingAPIService: GeocodingApiService,
    private fb: FormBuilder,
    private location: Location
  ) {}

  ngOnInit(): void {
    const idsString = this.route.snapshot.paramMap.get('id');
    this.Pids = idsString.split("#");
    this.PdocEntry = Number(this.route.snapshot.paramMap.get('docEntry'));
    this.PcarCode = this.route.snapshot.paramMap.get('carCode');
    this.PtrailerCode = this.route.snapshot.paramMap.get('trailerCode');
    this.PbaseType = this.route.snapshot.paramMap.get('baseType');
    this.PdeliveryType = this.route.snapshot.paramMap.get('deliveryType');
    this.Ptype = this.route.snapshot.paramMap.get('type');
    this.PloadUnload = this.route.snapshot.paramMap.get('loadUnload');
    this.PlineCounter = this.route.snapshot.paramMap.get('lineCounter');
    this.checkLogin();

    this.formLoad = this.fb.group({
      Qtd: [''],
      Weight: [''],
      Kms: ['', Validators.required],
      Date: ['', Validators.required],
      Hora: ['', Validators.required],
      Description: ['']
    });

    this.formUnload = this.fb.group({
      Qtd: [''],
      Weight: [''],
      Kms: ['', Validators.required],
      Date: ['', Validators.required],
      Hora: ['', Validators.required],
      Unload: ['', Validators.required],
      Description: [''],
      Destination: ['', Validators.required],
      Sign: [{ value: false, disabled: true }]
    });

    this.formIncidence = this.fb.group({
      Motive: ['', Validators.required],
      Date: ['', Validators.required],
      Hora: ['', Validators.required],
      Observations: [''],
      Files: ['']
    });

    this.formTrailerState = this.fb.group({
      LocalChange: ['VAL', Validators.required],
      NotherLocal: [''],
      Strap: ['', Validators.required],
      Rulers: ['', Validators.required],
      Canvas: [false],
      Tyres: [false],
      Trailer: [true],
      Observations: ['']
    });
  }

  ngAfterViewInit(): void {
    const self = this;
    this.fotosFileEl.nativeElement.addEventListener("ui5-change", event => {
      const files = event.target.files;
      if (!files.length) {
        console.log('No files')
      } else {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          //self.uploadFile(file, file.name, file.size, file.type, '', '')
          const reader = new FileReader();
          reader.readAsArrayBuffer(file);
          reader.onload = function (event) {
            self.uploadFile(reader.result, file.name, file.size, file.type,
              self.docRes, 'NOX_ORSV', '9','CMR');
          };
          reader.onerror = function (error) {
            console.log('Erro ao ler a imagem : ', error);
            self.notification.show('Error','Erro ao ler a imagem : '+ JSON.stringify(error));
          };
        }
      }
    });

    this.fotosCollectionEl.nativeElement.addEventListener("drop", event => {
      event.preventDefault();
      const files = event.dataTransfer.files;
      // Take the files from the drop event and create <ui5-upload-collection-item> from them
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        //console.log(file.size);
        //self.uploadFile(file, file.name, file.size, file.type, 'TEMP', 'NOX_ODCO');
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = function (event) {
          self.uploadFile(reader.result, file.name, file.size, file.type,
            self.docRes, 'NOX_ORSV', '9','CMR');
        };
        reader.onerror = function (error) {
          console.log('Erro ao ler a imagem : ', error);
          self.notification.show('Error','Erro ao ler a imagem : '+ JSON.stringify(error));
        };
      }
    });

    this.fotosCollectionEl.nativeElement.addEventListener("item-delete", event => {
      if(event && event.detail && event.detail.item) {
        this.deleteFile(event.detail.item.title);
      } else {
        this.notification.show('Error','Erro a apagar ficheiro');
      }
    });


    this.incFileEl.nativeElement.addEventListener("ui5-change", event => {
      const files = event.target.files;
      if (!files.length) {
        console.log('No files')
      } else {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          //self.uploadFile(file, file.name, file.size, file.type, '', '')
          const reader = new FileReader();
          reader.readAsArrayBuffer(file);
          reader.onload = function (event) {
            self.uploadFile(reader.result, file.name, file.size, file.type,
              'TEMP_'+localStorage.getItem('userid'), 'NOX_OINC', '','');
          };
          reader.onerror = function (error) {
            console.log('Erro ao ler a imagem : ', error);
            self.notification.show('Error','Erro ao ler a imagem : '+ JSON.stringify(error));
          };
        }
      }
    });

    this.filesCollectionEl.nativeElement.addEventListener("drop", event => {
      event.preventDefault();
      const files = event.dataTransfer.files;
      // Take the files from the drop event and create <ui5-upload-collection-item> from them
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        //console.log(file.size);
        //self.uploadFile(file, file.name, file.size, file.type, 'TEMP', 'NOX_ODCO');
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = function (event) {
          self.uploadFile(reader.result, file.name, file.size, file.type,
            'TEMP_'+localStorage.getItem('userid'), 'NOX_OINC', '','');
        };
        reader.onerror = function (error) {
          console.log('Erro ao ler a imagem : ', error);
          self.notification.show('Error','Erro ao ler a imagem : '+ JSON.stringify(error));
        };
      }
    });

    this.filesCollectionEl.nativeElement.addEventListener("item-delete", event => {
      if(event && event.detail && event.detail.item) {
        this.deleteFile(event.detail.item.title);
      } else {
        this.notification.show('Error','Erro a apagar ficheiro');
      }
    });

    this.tabCliEl.nativeElement.addEventListener("tab-select", event => {
      if(event.detail && this.task.needCarga == 'Y') this.tabIndex = event.detail.tabIndex;
    });

    this.tabDesEl.nativeElement.addEventListener("tab-select", event => {
      if(event.detail && this.task.needCarga != 'Y') this.tabIndex = event.detail.tabIndex;
    });

  }

  openLoad(task): void {
    this.docRes = task.DocEntry;
    this.articleId = task.LineId;
    this.lineIdRSV2 = task.LineIdRSV2 || null;
    this.taskType = task.Product;
    this.oldQtd = task.Qtd;
    this.oldWeight = task.Weight;
    this.formLoad.patchValue({
      Qtd: task.Qtd,
      Weight: task.Weight,
      Date: moment().format('YYYY-MM-DD'),
      Hora: moment().format('HH:mm'),
    });
    this.getDriverDetails('load');
    this.dialogLoadEl.nativeElement.show();
  }

  closeLoad(): void {
    this.docRes = null;
    this.articleId = null;
    this.lineIdRSV2 = null;
    this.oldQtd = null;
    this.oldWeight  = null;
    const Kms = this.kmsLoadEl.nativeElement;
    const Date = this.dateLoadEl.nativeElement;
    const Hora = this.timeLoadEl.nativeElement;
    Kms.setAttribute('value-state', '');
    Date.setAttribute('value-state', '');
    Hora.setAttribute('value-state', '');
    this.formLoad.reset();
    this.dialogLoadEl.nativeElement.close();
  }

  async saveLoad(): Promise<void> {
    const self = this;
    const controls = self.formLoad.controls;
    const Kms = self.kmsLoadEl.nativeElement;
    const Date = self.dateLoadEl.nativeElement;
    const Hora = self.timeLoadEl.nativeElement;
    Kms.setAttribute('value-state', '');
    // Datepicker & Timepicker state bug fix
    try {
      await this.timeout(100);
      if (Date.getAttribute('value-state') === 'Error') controls['Date'].setErrors({'incorrect': true});
      if (Hora.getAttribute('value-state') === 'Error') controls['Hora'].setErrors({'incorrect': true});
    } catch (e) {console.log(e)}
    Date.setAttribute('value-state', '');
    Hora.setAttribute('value-state', '');
    /** check form */
    if (self.formLoad.invalid) {
      Object.keys(controls).forEach(controlName => {
        controls[controlName].markAsTouched()
        if (controls[controlName].invalid) {
          try {eval(controlName).setAttribute('value-state', 'Error')} catch (e) {}
        }
      });
      return;
    }
    //Check if inserted date is bigger
    let confirmMsg = self.translate.instant('tasks.loadconfirm');
    try {
      if(this.taskDetailsC.length > 0 && this.taskDetailsC[0]['Date']) {
        const d1 = window.Date.parse(Date.value);
        const d2 = window.Date.parse(this.taskDetailsC[0]['Date']);
        if (d1 > d2) confirmMsg = self.translate.instant('tasks.loadconfirmdate');
      }
    } catch (e) { /*console.log(e);*/ }
    self.alertDialog.confirm(confirmMsg,function(){
      const formLoad = {
        ClientNOX: (self.task['needCarga'] == 'Y') ? self.task['ExpCode'] : self.task['DestCode'],
        Qtd: controls['Qtd'].value,
        oldQtd: self.oldQtd,
        Weight: controls['Weight'].value,
        oldWeight: self.oldWeight,
        Date: Date.value,//Datepicker Control Bug
        //DocEntry: self.task['DocEntry'],
        DocEntry: self.task['DocEntryPro'],
        DocEntryPro: self.task['DocEntryPro'],
        DocEntryRes: self.docRes.toString(),
        DocRes: self.docRes,
        U_DeliveryType: self.task['U_DeliveryType'] || null,
        Hora: controls['Hora'].value,
        Kms: controls['Kms'].value,
        Description: controls['Description'].value,
        //LineAddress: self.taskDetail['LineId'],
        LineAddress: self.articleId,
        LineIdRSV2: self.lineIdRSV2,
        Reserva: self.docRes,
        U_CodEvent: "CAR",
        needCarga: self.task['needCarga'],
        OpenIncidences: self.OpenIncidences
        //oldQtd: controls['Qtd'].value,
        //oldWeight: controls['Weight'].value
      };

      const details = {
        CarPlate: self.carPlate,
        DriverName: localStorage.getItem('user'),
        Count: self.tasksNumber,
        CODReceived: 'N'
      };

      self.loading.show();
      self.apiService.saveLoadOrUnload(details, formLoad).subscribe(data => {
        if(self.PdeliveryType && self.PdeliveryType == 'REC' || self.PdeliveryType == 'FTL'
          && (self.rows[self.rows.length-1].DocEntryRes == self.docRes &&
            self.rows[self.rows.length-1].DocEntryPro == self.task['DocEntryPro'])) {
          self.formLoad.reset();
          self.closeLoad();
          //self.notification.show('Success','Submetido com sucesso');
          self.getTask();
          self.openTrailerState();
        } else {
          self.formLoad.reset();
          self.closeLoad();
          self.notification.show('Success','Submetido com sucesso');
          self.getTask();
        }
      },error => {
        self.loading.hide();
        self.notification.show('Error',JSON.stringify(error['error']));
        console.log(error.error);
      },() => {
        self.loading.hide();
      });
    },function(){});
  }

  openUnload(task, all = false): void {
    //console.log(task);
    this.unloadAll = all;
    if(!all){
      this.docRes = task.DocEntry;
      this.articleId = task.LineId;
      this.lineIdRSV2 = task.LineIdRSV2 || null;
      this.taskType = task.Product;
      this.oldQtd = task.Qtd;
      this.codTask = {
        COD: task?.U_Cod,
        CODType: task?.U_CodType,
        CODValue: task?.U_CodValue
      }
      this.formUnload.patchValue({
        Qtd: task.Qtd,
        Weight: task.Weight,
        Date: moment().format('YYYY-MM-DD'),
        Hora: moment().format('HH:mm'),
      });
    } else {
      this.docRes = this.task['DocEntry'];
      // Verify Qty & Height
      this.formUnload.patchValue({
        Date: moment().format('YYYY-MM-DD'),
        Hora: moment().format('HH:mm'),
      });
    }
    this.getFiles(this.docRes, 'NOX_ORSV', 'CMR');
    this.getDriverDetails('unload');
    this.dialogUnloadEl.nativeElement.show();
  }

  closeUnload(cancel = false): void {
    console.log(this.fotosCMR);
    if(cancel) {
      for (let i = 0; i < this.fotosCMR.length; i++) {
        this.deleteFile(this.fotosCMR[i].id);
      }
    }
    this.signaturePad.clear();
    this.docRes = null;
    this.articleId = null;
    this.lineIdRSV2 = null;
    this.oldQtd = null;
    this.codTask = {
      U_Cod: '',
      U_CodType: '',
      U_CodValue: ''
    }
    const Kms = this.kmsUnloadEl.nativeElement;
    const Date = this.dateUnloadEl.nativeElement;
    const Hora = this.timeUnloadEl.nativeElement;
    const Unload = this.unloadTimeEl.nativeElement;
    const Destination = this.destinationUnloadEl.nativeElement;
    Kms.setAttribute('value-state', '');
    Date.setAttribute('value-state', '');
    Hora.setAttribute('value-state', '');
    Unload.setAttribute('value-state', '');
    Destination.setAttribute('value-state', '');
    this.formUnload.reset();
    this.dialogUnloadEl.nativeElement.close();
  }

  saveUnload(): void {
    if(this.codTask?.COD == 'Y' && this.codTask?.CODType !== 'T') {
      this.alertDialog.confirm(this.translate.instant('tasks.receivedCOD')+
        ' '+this.codTask?.CODValue + '€ ?', () => {
        this._saveUnload('Y');
      }, () => {});
    } else {
      this._saveUnload();
    }
  }

  async _saveUnload(codReceived = 'N'): Promise<void> {
    const self = this;
    const controls = self.formUnload.controls;
    const Kms = self.kmsUnloadEl.nativeElement;
    const Date = self.dateUnloadEl.nativeElement;
    const Hora = self.timeUnloadEl.nativeElement;
    const Unload = self.unloadTimeEl.nativeElement;
    const Destination = self.destinationUnloadEl.nativeElement;
    Kms.setAttribute('value-state', '');
    // Datepicker & Timepicker state bug fix
    try {
      await this.timeout(100);
      if (Date.getAttribute('value-state') === 'Error') controls['Date'].setErrors({'incorrect': true});
      if (Hora.getAttribute('value-state') === 'Error') controls['Hora'].setErrors({'incorrect': true});
    } catch (e) {console.log(e)}
    Date.setAttribute('value-state', '');
    Hora.setAttribute('value-state', '');
    Unload.setAttribute('value-state', '');
    Destination.setAttribute('value-state', '');
    /** check form */
    if (self.formUnload.invalid) {
      Object.keys(controls).forEach(controlName => {
        controls[controlName].markAsTouched()
        if (controls[controlName].invalid) {
          try {eval(controlName).setAttribute('value-state', 'Error')} catch (e) {}
        }
      });
      return;
    }

    if(self.fotosCMR.length == 0) {
      self.notification.show('Error','Foto CMR obrigatória');
      return;
    }
    //Check if inserted date is bigger
    let confirmMsg = self.translate.instant('tasks.unloadconfirm');
    try {
      if(this.taskDetailsD.length > 0 && this.taskDetailsD[0]['Date']) {
        const d1 = window.Date.parse(Date.value);
        const d2 = window.Date.parse(this.taskDetailsD[0]['Date']);
        if (d1 > d2) confirmMsg = self.translate.instant('tasks.unloadconfirmdate');
      }
    } catch (e) { /*console.log(e);*/ }
    self.alertDialog.confirm(confirmMsg, function(){
      const formUnload = {
        ClientNOX: (self.task['needCarga'] == 'Y') ? self.task['ExpCode'] : self.task['DestCode'],
        Qtd: controls['Qtd'].value,
        oldQtd: self.oldQtd,
        Date: Date.value,//Datepicker Control Bug
        //DocEntry: self.task['DocEntry'],
        DocEntry: self.task['DocEntryPro'],
        DocEntryPro: self.task['DocEntryPro'],
        DocEntryRes: self.docRes.toString(),
        DocRes: self.docRes,
        U_DeliveryType: self.task['U_DeliveryType'] || null,
        Hora: controls['Hora'].value,
        Kms: controls['Kms'].value,
        //LineAddress: self.taskDetail['LineId'],
        LineAddress: self.articleId,
        LineIdRSV2: self.lineIdRSV2,
        Reserva: self.docRes,
        Signature: (controls['Sign'].value ? self.signaturePad.toDataURL('image/png') : null),
        Time:  controls['Unload'].value,
        U_CodEvent: "DES",
        U_Responsable: controls['Destination'].value,
        isAssign: controls['Sign'].value,
        needCarga: self.task['needCarga'],
        OpenIncidences: self.OpenIncidences
        //oldQtd: controls['Qtd'].value
      };
      const details = {
        CarPlate: self.carPlate,
        DriverName: localStorage.getItem('user'),
        Count: self.tasksNumber,
        CODReceived: codReceived
      };

      if(self.unloadAll) {
        formUnload['All'] = true;
        formUnload['AllRes'] = [];
        for(let k = 0;k < self.taskDetail.length;k++){
          formUnload['AllRes'].push({
              "DocRes": self.taskDetail[k].DocEntry,
              "LineAddress": self.taskDetail[k].LineId,
              "ClientNOX": self.taskDetail[k].ClientNOX
          });
        }
      }

      self.loading.show();
      self.apiService.saveLoadOrUnload(details, formUnload).subscribe(data => {
        console.log(data);
        if(self.PdeliveryType && self.PdeliveryType == 'REC' || self.PdeliveryType == 'FTL'
          && (self.rows[self.rows.length-1].DocEntryRes == self.docRes &&
            self.rows[self.rows.length-1].DocEntryPro == self.task['DocEntryPro'])) {
          self.formUnload.reset();
          self.closeUnload();
          //self.notification.show('Success','Submetido com sucesso');
          self.getTask();
          self.openTrailerState();
        } else {
          self.formUnload.reset();
          self.closeUnload();
          self.notification.show('Success','Submetido com sucesso');
          self.getTask();
        }
      },error => {
        self.loading.hide();
        self.notification.show('Error',JSON.stringify(error['error']));
        console.log(error.error);
      },() => {
        self.loading.hide();
      });
    },function(){});
  }

  openIncidence(): void {
    this.getFiles('TEMP_'+localStorage.getItem('userid'), 'NOX_OINC', '');
    this.formIncidence.patchValue({
      Date: moment().format('YYYY-MM-DD'),
      Hora: moment().format('HH:mm'),
    });
    this.dialogIncidenceEl.nativeElement.show();
  }

  closeIncidence(cancel = false): void {
    const Motive = this.motiveIncidenceEl.nativeElement;
    const Date = this.dateIncidenceEl.nativeElement;
    const Hora = this.timeIncidenceEl.nativeElement;
    Motive.setAttribute('value-state', '');
    Date.setAttribute('value-state', '');
    Hora.setAttribute('value-state', '');
    this.formIncidence.reset();
    if(cancel) {
      for (let i = 0; i < this.filesIncidences.length; i++) {
        if(this.filesIncidences[i]['artigo'].toString().includes('TEMP_')) {
          this.deleteFile(this.filesIncidences[i]['id']);
        }
      }
    }
    this.dialogIncidenceEl.nativeElement.close();

  }

  async saveIncidence(): Promise<void> {
    const controls = this.formIncidence.controls;
    const Motive = this.motiveIncidenceEl.nativeElement;
    const Date = this.dateIncidenceEl.nativeElement;
    const Hora = this.timeIncidenceEl.nativeElement;
    const DateLoad = this.dateLoadEl.nativeElement;
    const DateUnload = this.dateUnloadEl.nativeElement;
    Motive.setAttribute('value-state', '');
    // Datepicker & Timepicker state bug fix
    try {
      await this.timeout(100);
      if (Date.getAttribute('value-state') === 'Error') controls['Date'].setErrors({'incorrect': true});
      if (Hora.getAttribute('value-state') === 'Error') controls['Hora'].setErrors({'incorrect': true});
    } catch (e) {console.log(e)}
    Date.setAttribute('value-state', '');
    Hora.setAttribute('value-state', '');
    /** check form */
    if (this.formIncidence.invalid) {
      Object.keys(controls).forEach(controlName => {
        controls[controlName].markAsTouched()
        if (controls[controlName].invalid) {
          try {eval(controlName).setAttribute('value-state', 'Error')} catch (e) {}
        }
      });
      return;
    }

    const details = {
      CarPlate: this.carPlate,
      DriverName: localStorage.getItem('user')
    };
    const formIncidence = {
      BaseType: this.task['U_BaseType'],
      Date: Date.value,//Datepicker Control Bug
      DocEntry: this.docRes.toString(),
      Incidence: controls['Motive'].value,
      MapBaseType: 'NOX_OPRO',
      MapEntry: this.task['DocEntryPro'],
      Time: controls['Hora'].value,
      Comments: controls['Observations'].value
    };
    const controlsLoad = this.formLoad.controls;
    const formLoad = {
      Qtd: controlsLoad['Qtd'].value,
      Weight: controlsLoad['Weight'].value,
      Kms: controlsLoad['Kms'].value,
      Date: DateLoad.value,//Datepicker Control Bug
      Hora: controlsLoad['Hora'].value,
      Description: controlsLoad['Description'].value
    };
    const controlsUnload = this.formUnload.controls;
    const formUnload = {
      Qtd: controlsUnload['Qtd'].value,
      Weight: controlsUnload['Weight'].value,
      Kms: controlsUnload['Kms'].value,
      Date: DateUnload.value,//Datepicker Control Bug
      Hora: controlsUnload['Hora'].value,
      Unload: controlsUnload['Unload'].value,
      Description: controlsUnload['Description'].value,
      Destination: controlsUnload['Destination'].value
    };
    const allData = (this.task?.CarDest == 'CAR' ? formLoad : formUnload );

    this.loading.show();
    this.apiService.saveIncidence(details, formIncidence, allData).subscribe(data => {
      this.OpenIncidences.push(formIncidence);
      this.notification.show('Success','Incidência submetida com sucesso');
      this.formIncidence.reset();
      this.closeIncidence();
    },error => {
      this.loading.hide();
      this.notification.show('Error',JSON.stringify(error['error']));
      console.log(error.error);
    },() => {
      this.loading.hide();
    });
  }

  openIncidences(): void {
    this.loading.show();
    const ids = this.route.snapshot.paramMap.get('id');
    this.apiService.getIncidences(ids)
      .subscribe(data => {
        // @ts-ignore
        this.incidences = data
        this.dialogIncidencesEl.nativeElement.show();
      },error => {
        this.loading.hide();
        this.notification.show('Error',JSON.stringify(error['error']));
        console.log(error.error);
      },() => {
        this.loading.hide();
      });

  }

  closeIncidences(): void {
    this.dialogIncidencesEl.nativeElement.close();
  }

  openSign(id): void {
    this.dialogSignEl.nativeElement.show();
    if(this.signaturePad.queryPad()._canvas.width != (this.dialogSignEl?.nativeElement.offsetWidth - 40)) {
      this.signaturePad.queryPad()._canvas.width = this.dialogSignEl?.nativeElement.offsetWidth - 40;
      this.signaturePad.queryPad()._canvas.height = this.dialogSignEl?.nativeElement.offsetHeight - 135;
    }
  }

  closeSign(): void {
    this.dialogSignEl.nativeElement.close();
  }

  saveSign(): void {
    this.formUnload.patchValue({
      Sign: true
    });
    this.dialogSignEl.nativeElement.close();
  }

  openFiles(id): void {
    this.dialogFilesEl.nativeElement.show();
  }

  closeFiles(): void {
    this.dialogFilesEl.nativeElement.close();
  }

  openFilesInc(id): void {
    this.dialogFilesIncEl.nativeElement.show();
  }

  closeFilesInc(): void {
    this.dialogFilesIncEl.nativeElement.close();
  }

  openFilesDispatches(id): void {
    this.gmr = false;
    this.getFiles(id, 'NOX_ORSV', '',["15", "1"]);
    this.dialogFilesDispatchesEl.nativeElement.show();
  }

  closeFilesDispatches(): void {
    this.dialogFilesDispatchesEl.nativeElement.close();
  }

  openFilesGMR(id): void {
    this.gmr = true;
    this.getFiles(id, 'NOX_OPRO', '',["14"]);
    this.dialogFilesDispatchesEl.nativeElement.show();
  }

  closeFilesGMR(): void {
    this.dialogFilesDispatchesEl.nativeElement.close();
  }

  downloadFile(event,url,filename): void {
    let preview = event?.srcElement.classList.contains('preview-link');
    if(event?.isMarked == 'link' && !preview) {
      this.apiService.downloadFile(url,filename);
    }
  }

  previewFile(event,url,filename): void {
    let preview = event?.srcElement.classList.contains('preview-link');
    if(event?.isMarked == 'link' && preview) {
      const cleanURL = this.apiService.getFileURL(url);
      const ext = filename.substring(filename.lastIndexOf('.') + 1);
      this.isPdf = ext == 'pdf';
      this.fileViewer = {
        src: cleanURL,
        name: filename
      }
      this.dialogPreviewEl.nativeElement.show();
    }
  }

  closeDialogPreview(): void {
    this.dialogPreviewEl.nativeElement.close();
  }

  openMap(): void {
    this.latitude = null;
    this.longitude = null;
    //if(this.task['U_LineType'] == 'E') {
      /*if(this.task['LatitudeDest'] && this.task['LongitudeDest']) {
        this.latitude = parseFloat(this.task['LatitudeDest']);
        this.longitude = parseFloat(this.task['LongitudeDest']);
        console.log(this.latitude,this.longitude);
      } else {*/
        let address = this.task['LocalAddressExp'] ? this.task['LocalAddressExp'] : this.task['LocalAddress'];

        if(this.tabIndex !== null && this.tabIndex !== undefined) {
          try {
            if(this.task.needCarga == 'Y') {
              var tempAddress = this.taskDetailsC[this.tabIndex]['Street'] + ' ' + this.taskDetailsC[this.tabIndex]['City'] + ' ' +
                this.taskDetailsC[this.tabIndex]['ZipCode'] + this.taskDetailsC[this.tabIndex]['Country'];
              address = tempAddress;
            } else {
              var tempAddress = this.taskDetailsD[this.tabIndex]['Street'] + ' ' + this.taskDetailsD[this.tabIndex]['City'] + ' ' +
                this.taskDetailsD[this.tabIndex]['ZipCode'] + this.taskDetailsD[this.tabIndex]['Country'];
              address = tempAddress;
            }
          } catch (e) {}
        }
        this.geocodingAPIService
          .findFromAddress(address)
          .subscribe(response => {
            if (response.status == 'OK') {
              this.latitude = response.results[0].geometry.location.lat;
              this.longitude = response.results[0].geometry.location.lng;
            } else if (response.status == 'ZERO_RESULTS') {
              console.log('geocodingAPIService', 'ZERO_RESULTS', response.status);
            } else {
              console.log('geocodingAPIService', 'Other error', response.status);
            }
          });
    /* }
   } else {
     if (this.task['LatitudeExp'] && this.task['LongitudeExp']) {
       this.latitude = parseFloat(this.task['LatitudeExp']);
       this.longitude = parseFloat(this.task['LongitudeExp']);
       console.log(this.latitude,this.longitude);
     } else {
       this.geocodingAPIService
         .findFromAddress(this.task['LocalAddressExp'])
         .subscribe(response => {
           if (response.status == 'OK') {
             this.latitude = response.results[0].geometry.location.lat;
             this.longitude = response.results[0].geometry.location.lng;
           } else if (response.status == 'ZERO_RESULTS') {
             console.log('geocodingAPIService', 'ZERO_RESULTS', response.status);
           } else {
             console.log('geocodingAPIService', 'Other error', response.status);
           }
         });
     }
   }*/
    this.dialogMapEl.nativeElement.show();
  }

  closeMap(): void {
    this.dialogMapEl.nativeElement.close();
  }

  openGoogleMaps(): void {
    let address  = this.latitude.toString() + ',' + this.longitude.toString() + '&amp;ll=';
    /*if(this.task['U_LineType'] == 'E') {
      if(this.task['LatitudeDest'] && this.task['LongitudeDest']) {
        address = this.task['LatitudeDest'] + ',' + this.task['LongitudeDest'] + '&amp;ll=';
      } else {
        address = encodeURI(this.task['LocalAddressDest']);
      }
    } else {
      if(this.task['LatitudeExp'] && this.task['LongitudeExp']) {
        address = this.task['LatitudeExp'] + ',' + this.task['LongitudeExp'] + '&amp;ll=';
      } else {
        address = encodeURI(this.task['LocalAddressExp']);
      }
    }*/

    if(address) {
      const isIOS = (/iPad|iPhone|iPod|iPad Simulator|iPhone Simulator|iPod Simulator/.test(navigator.platform) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
        !window.MSStream

      if(isIOS) {
        window.open('comgooglemaps://?daddr='+address, '_system');
      } else {
        window.open('http://maps.google.com/?daddr='+address, '_system');
      }
    } else {
      this.notification.show('Error','Cordenadas invalidas');
    }
  }

  clickedMarker(label: string, index: number): void {
    console.log(`clicked the marker: ${label || index}`)
  }

  openGMR() {
    window.open('https://www.tax.service.gov.uk/driver-inspection-notification/search', '_blank');
  }

  openTrailerState(): void {
    this.formTrailerState.patchValue({
      LocalChange: 'VAL',
      Trailer: true,
    });
    this.dialogTrailerStateEl.nativeElement.show();
  }

  closeDialogTrailerState(): void {
    const Strap = this.strapEl.nativeElement;
    const Rulers = this.rulersEl.nativeElement;
    Strap.setAttribute('value-state', '');
    Rulers.setAttribute('value-state', '');
    this.formTrailerState.reset();
    this.dialogTrailerStateEl.nativeElement.close();
  }

  saveTrailerState(): void {
    const controls = this.formTrailerState.controls;
    const Strap = this.strapEl.nativeElement;
    const Rulers = this.rulersEl.nativeElement;
    Strap.setAttribute('value-state', '');
    Rulers.setAttribute('value-state', '');
    /** check form */
    console.log(this.formTrailerState);
    if (this.formTrailerState.invalid) {
      Object.keys(controls).forEach(controlName => {
        controls[controlName].markAsTouched()
        if (controls[controlName].invalid) {
          try {eval(controlName).setAttribute('value-state', 'Error')} catch (e) {}
        }
      });
      return;
    }

    const formTrailer = {
      VehicleCode: this.PcarCode,
      Trailer: this.PtrailerCode,
      Reboque: this.PtrailerCode,
      LocalChange: controls['LocalChange'].value,
      NotherLocal: controls['NotherLocal'].value,
      Strap: controls['Strap'].value,
      Rulers: controls['Rulers'].value,
      Canvas: controls['Canvas'].value,
      YesCanvas: (controls['Canvas'].value === true),
      NoCanvas: (controls['Canvas'].value === false),
      Tyres: controls['Tyres'].value,
      YesTyres: (controls['Tyres'].value === true),
      NoTyres: (controls['Tyres'].value === false),
      Trailer_: controls['Trailer'].value,
      YesTrailer: (controls['Trailer'].value === true),
      NoTrailer: (controls['Trailer'].value === false),
      Observations: controls['Observations'].value,
    };

    this.loading.show();
    this.apiService.saveChangeTrailer(formTrailer).subscribe(data => {
      this.notification.show('Success','Submetido com sucesso');
      this.sendEmailTrailer(formTrailer);
      this.formTrailerState.reset();
      this.closeDialogTrailerState();
    },error => {
      this.loading.hide();
      this.notification.show('Error',JSON.stringify(error['error']));
      console.log(error.error);
    },() => {
      this.loading.hide();
    });


  }

  sendEmailTrailer(trailer): void {
    if (this.configs && this.configs['emailsToSend']) {
      const email = {
        to:  this.configs['emailsToSend'],
        cc: '',
        bbc: '',
        subject: 'Checkin - Motorista',
        bodyemail: ''
      };
      email.bodyemail = '<p>Exmo. Colaborador,</p><p>O motorista ' + localStorage.getItem('user') + ' efetuou checkin com os seguintes dados: </p>';
      email.bodyemail += ' <p>Veiculo: ' + trailer.VehicleCode + '  </p>';
      email.bodyemail += ' <p>Reboque: ' + trailer.Reboque + '  </p>';
      email.bodyemail += ' <p>Local Troca: ' + trailer.LocalChange + '  </p>';
      email.bodyemail += ' <p>Nº Cintas: ' + trailer.Strap + '  </p>';
      email.bodyemail += ' <p>Nº Réguas Travamento: ' +trailer.Rulers + '  </p>';
      email.bodyemail += ' <p>Lona Com Cortes: ' + (trailer.Canvas === true ? this.translate.instant('tasks.yes') : this.translate.instant('tasks.no')) + '  </p>';
      email.bodyemail += ' <p>Pneus com danos visiveis: ' + (trailer.Tires === true ? this.translate.instant('tasks.yes') : this.translate.instant('tasks.no'))+ '  </p>';
      email.bodyemail += ' <p>Reboque limpo: ' + (trailer.Trailer_ === true ? this.translate.instant('tasks.yes') : this.translate.instant('tasks.no')) + '  </p>';

      if(trailer.Observation)
        email.bodyemail += ' <p>Observações: ' + trailer.Observation + '  </p>';

      this.sendEmail(email);
    }
  }

  goToTasks(): void {
    if(this.PdocEntry) {
      this.router.navigate(['tasks', this.PdocEntry])
    } else {
      this.router.navigate(['tasks'])
    }

  }

  goBack(): void {
    this.location.back();
  }

  checkLogin(): void {
    this.loading.show();
    this.apiService.checkLogin(moment().format('YYYY-MM-DD'))
      .subscribe(data => {
        if(!data['U_Status'] || data['U_Status'] == "CHECKOUT") {
          this.router.navigate(['/']);
          this.notification.show('Info','Efectue o Checkin!');
        } else {
          // @ts-ignore
          this.carPlate = data.CarPlate;
          setTimeout(() => {
            this.getTask();
          },100);
        }
      },error => {
        this.loading.hide();
        this.notification.show('Error',JSON.stringify(error['error']));
        console.log(error.error);
      },() => {
        this.loading.hide();
      });
  }

  getSysConfigs(): void {
    //this.loading.show();
    this.apiService.getSysConfigs()
      .subscribe(data => {
        this.configs = data;
      },error => {
        this.loading.hide();
        //this.notification.show('Error',JSON.stringify(error['error']));
        console.log(error.error);
      },() => {
        //this.loading.hide();
      });
  }

  getTask(): void {
    let append = false;
    const body = {
      DocEntry: this.PdocEntry,
      CarCode: this.PcarCode,
      LoadUnload: this.PloadUnload
    }
    //if(this.Ptype == 'IMP' || this.PdeliveryType == 'FTL' || this.PbaseType == 'NOX_ORSV') {
    if(this.Ptype == 'IMP' ||  this.PdeliveryType == 'FTL' || this.PbaseType == 'NOX_ORSV'
      && (this.Ptype !== 'NAC' && this.PdeliveryType !== 'EDI')) {
      body['Type'] = this.Ptype;
      if((this.Ptype == 'NAC' || this.Ptype == 'IMP') && this.PdeliveryType == 'REC'){
        // TODO
      }else {
        append = true;
      }
    } else {
      body['DeliveryType'] = this.PdeliveryType;
      body['BaseType'] = this.PbaseType;
    }
    //this.getTaskDetail(id);
    this.loading.show();
    this.apiService.getTasks(body/*{
      DocEntry: this.PdocEntry,
      CarCode: this.PcarCode,
      //BaseType: this.PbaseType,
      Type: this.Ptype,
      //DeliveryType: this.PdeliveryType,
      LoadUnload: this.PloadUnload
      }*/).subscribe(r => {
      var newData = [];
      // @ts-ignore
      for(var k=0;k < r.length;k++) {
        var row = r[k];
        if(row.U_DeliveryType == 'EDI' || row.U_DeliveryType == 'DIR'){//"Entrega Direta"
          row.DeliveryType = 'Entrega Direta';
        }else if(row.U_DeliveryType == 'ETE' ){//ETE --> terminal
          row.DeliveryType = 'Entrega Terminal Próprio';
        }else if(row.U_DeliveryType == 'REC'){//REC--> recolha
          row.DeliveryType = 'Recolha';
        }else if(row.U_DeliveryType == 'EAG' || row.U_DeliveryType == 'AGN' ){//EAG--> Agent
          row.DeliveryType = 'Entrega Agente';
        }else if(row.U_DeliveryType == 'FTL' || row.U_DeliveryType == 'EDI'){//FTL--> Direto
          row.DeliveryType = 'Direto-Direto';
        }
        if(row.U_Type == 'IMP'){
          row.Type = 'Importação';
        }else if(row.U_Type == 'EXP'){
          row.Type = 'Exportação';
        }else{
          row.Type = 'Nacional';
        }
        if(row.U_Type == 'NAC'){
          if(row.RecEntCode == 'R' && row.WhsName){
            row.visibleTypeWhs = true;
          }else{
            row.visibleTypeWhs = false;
          }
        }else if( row.WhsName){
          row.visibleTypeWhs = true;
        }
        if((row.U_Type == 'NAC' && row.RecEntCode == 'R') || row.U_Type !== 'NAC'){
          row.visibleDeliveryType = true;
        }else{
          row.visibleDeliveryType = true;
        }
        /*if(row.U_Type == 'NAC'){
          row.U_DeliveryType = 'ETE';
        }*/
        row.valid = true;
        if(row.U_DeliveryType == 'FTL'){
          //significa que é direto e que precisa tb de recolha
        }else if(newData && newData.length == 0){  //aqui é nacional ou exportacaos
        //}else if(!append){  //aqui é nacional ou exportacaos
          row.valid = false;
          newData.push(row);
        }else{
          var datesMax = [], datesMin = [];
          for(var m=0;m<newData.length;m++){
            var rowNew = newData[m];
            //tudo ok, até à importacao:
            if(row.RecEntCode == 'R'){
              //expedidor igual?
              if(rowNew.RecEntCode == row.RecEntCode && rowNew.ExpCode == row.ExpCode && rowNew.ExpAdd == row.ExpAdd){
                row.valid = false;
                //aqui temos que ver as datas; agrupar o destinatário e afins
                rowNew.DocEntryRes = rowNew.DocEntryRes + '#' + row.DocEntryRes;
                if(row.Status !== 'End') rowNew.Status = row.Status || '';
                datesMin.push(new Date(rowNew.PrevDateCar));
                var minDate = new Date(Math.min.apply(null, datesMin));
                rowNew.PrevDateCar = moment(minDate).format("YYYY-MM-DD HH:mm:ss");
                //rowNew.PrevDateCar = moment(minDate).format("YYYY-MM-DD");
                //datesMax.push(new Date(rowNew.PrevDateDesc));
                //var maxDate = new Date(Math.max.apply(null, datesMax));
                //rowNew.PrevDateCar = moment(maxDate).format("YYYY-MM-DD");
                break;
              }
            }
            if(row.RecEntCode == 'E') {
              //destinatário igual? DestAdd: -1     DestCode: "F1005"
              if(rowNew.RecEnt == row.RecEnt && rowNew.DestCode == row.DestCode && rowNew.DestAdd == row.DestAdd){
                row.valid = false;
                //aqui temos que ver as datas; agrupar o destinatário e afins
                rowNew.DocEntryRes = rowNew.DocEntryRes + '#' + row.DocEntryRes;
                if(row.Status !== 'End') rowNew.Status = row.Status || '';
                //datesMin.push(new Date(rowNew.PrevDateCar));
                //var minDate = new Date(Math.min.apply(null, datesMin));
                //rowNew.PrevDateCar = moment(minDate).format("YYYY-MM-DD");
                datesMax.push(new Date(rowNew.PrevDateDesc));
                var maxDate = new Date(Math.max.apply(null, datesMax));
                rowNew.PrevDateCar = moment(maxDate).format("YYYY-MM-DD HH:mm:ss");
                //rowNew.PrevDateCar = moment(maxDate).format("YYYY-MM-DD");
                break;
              }
            }
          }
        }
        if(row.valid) {
          newData.push(row);
        }
      }
      this.rows = newData;

      //console.log(this.rows[this.PlineCounter])
      if(this.rows[this.PlineCounter]) {
        this.task = this.rows[this.PlineCounter];
      } else {
        // @ts-ignore
        this.task = this.rows.find(t => t['DocEntryRes'] == this.Pids[0]);
      }
      // @ts-ignore
      this.tasksNumber = this.rows.length;
      if(!this.task) this.router.navigate(['tasks']);
      this.getTaskDetail(this.Pids,
        this.task['LineIdRes'],
        this.task['Product'],
        this.task['RecEntCode'] == 'R' ? 'C' : 'D',
        this.task['RecEntCode'] == 'R' ? this.task['ExpAdd'] : this.task['DestAdd'],
        this.task['RecEntCode'] == 'R' ? (this.task['LoadClient'] || this.task['ExpCode']) : this.task['DestCode']);
    },error => {
      this.loading.hide();
      this.notification.show('Error',JSON.stringify(error['error']));
      console.log(error.error);
    },() => {
      this.loading.hide();
    });

  }

  getTaskDetail(ids, linedId, product, type, expadd, loadclient): void {
    this.loading.show();
    forkJoin([
      this.apiService.getTask(ids, linedId, product, type, expadd, loadclient),
      this.apiService.getTaskDetails(ids,'D'),
      this.apiService.getTaskDetails(ids,'C'),
      this.apiService.getIncidencesTypes("DRIVER")
    ])
    .subscribe(data => {
      this.taskDetail = data[0];
      // @ts-ignore
      this.taskDetailsD = data[1];
      // @ts-ignore
      this.taskDetailsC = data[2];
      // @ts-ignore
      this.incidenceTypes = data[3];
      this.incidenceTypes.unshift({ Code: '', Name: ''});

      let count = 0;
      this.unloaded = false;
      //this.loadDisabled = false;
      //this.unloadDisabled = false;
      for(let i = 0; i < this.taskDetail.length; i++) {
        this.taskDetail[i].loadDisabled = false;
        this.taskDetail[i].unloadDisabled = false;
        if(this.task?.RecEnt === 'Recolha') {
          if(this.taskDetail[i]?.Cargas > 0 && this.task?.needCarga == 'Y') {
            //this.loadDisabled = true;
            this.taskDetail[i].loadDisabled = true;
          } else if(this.taskDetail[i]?.Descargas > 0 && this.task?.needCarga == 'N') {
            //isto significa que já foi lido e registado
            //this.loadDisabled = true;
            this.taskDetail[i].loadDisabled = true;
          } else{
            //this.loadDisabled = false;
            this.taskDetail[i].loadDisabled = false;
          }
        } else {
          if(this.taskDetail[i]?.Cargas > 0 && this.task?.needCarga == 'Y') {
            //this.unloadDisabled = true;
            this.taskDetail[i].unloadDisabled = true;
            count += 1;
          } else if(this.taskDetail[i]?.Descargas > 0 && this.task?.needCarga == 'N') {
            //isto significa que já foi lido e registado
            //this.unloadDisabled = true;
            this.taskDetail[i].unloadDisabled = true;
            count += 1;
          } else {
            //this.unloadDisabled = false;
            this.taskDetail[i].unloadDisabled = false;
          }
          if(count == this.taskDetail.length) {
            this.unloaded = true;
          }
        }
      }
    },error => {
      this.loading.hide();
      this.notification.show('Error',JSON.stringify(error['error']));
      console.log(error.error);
    },() => {
      this.loading.hide();
    });
  }

  getDriverDetails(type = null): void {
    this.loading.show();
    this.apiService.getDriverDetails()
      .subscribe(data => {
        if(data) {
          if(type == 'load') {
            this.formLoad.patchValue({
              Kms: data['Kms']
            });
          } else {
            this.formUnload.patchValue({
              Kms: data['Kms']
            });
          }
        }
      },error => {
        this.loading.hide();
        this.notification.show('Error',JSON.stringify(error['error']));
        console.log(error.error);
      },() => {
        this.loading.hide();
      });
  }

  getFiles(code, type, info,category = null): void {
    setTimeout(() => {
      this.loading.show();
      this.apiService.getFiles(code,type,info,category)
        .subscribe(data => {
          if(category) {
            // @ts-ignore
            this.filesDispatches = data;
          } else {
            if(type == 'NOX_OINC') {
              // @ts-ignore
              this.filesIncidences = data;
            } else {
              // @ts-ignore
              this.fotosCMR = data;
            }
          }
        },error => {
          this.loading.hide();
          this.notification.show('Error',JSON.stringify(error['error']));
          console.log(error.error);
        },() => {
          this.loading.hide();
        });
    },100);

  }

  uploadFile(file, name, size, type, objcode, objtype, category, info): void {
    this.loading.show();
    this.apiService.uploadFile(file, name, size, type, objcode, objtype, category, info)
      .subscribe(data => {
        if(objtype == 'NOX_OINC') {
          this.getFiles('TEMP_'+localStorage.getItem('userid'), 'NOX_OINC', '');
        } else {
          this.getFiles(this.docRes, 'NOX_ORSV', 'CMR');
        }

      },error => {
        this.loading.hide();
        this.notification.show('Error',JSON.stringify(error['error']));
        console.log(error.error);
      },() => {
        this.loading.hide();
      });
  }

  updateFilesTemp(id, code, type): void {
    this.loading.show();
    this.apiService.updateFilesTemp(id, code, type)
      .subscribe(data => {
        console.log(data);
      },error => {
        this.loading.hide();
        this.notification.show('Error',JSON.stringify(error['error']));
        console.log(error.error);
      },() => {
        this.loading.hide();
      });
  }

  deleteFile(id): void {
    const self = this;
    //self.alertDialog.confirm(self.translate.instant('general.confirmdelete'),function(){
      self.loading.show();
      self.apiService.deleteFile(id)
        .subscribe(data => {
          if(self.docRes) {
            self.getFiles(self.docRes, 'NOX_ORSV', 'CMR');
          }
          self.getFiles('TEMP_'+localStorage.getItem('userid'), 'NOX_OINC', '');
        },error => {
          self.loading.hide();
          self.notification.show('Error',JSON.stringify(error['error']));
          console.log(error.error);
        },() => {
          self.loading.hide();
        });
    //},function(){});
  }

  clearFiles(event): void {
    try {
      if(event.target?.files.length > 0) {
        const input = event.target?.shadowRoot.querySelector('input');
        if(input) {
          input.value = null;
        }
      }
    } catch (e) {
      //console.log(e);
    }
  }

  sendEmail(data): void {
    this.loading.show();
    this.apiService.sendEmail(data)
      .subscribe(data => {
        console.log(data);
      },error => {
        this.loading.hide();
        this.notification.show('Error',JSON.stringify(error['error']));
        console.log(error.error);
      },() => {
        this.loading.hide();
      });
  }

  timeout(ms): Promise<unknown> {
    this.loading.show();
    return new Promise((resolve) => setTimeout(() => {
      this.loading.hide();
      resolve();
    }, ms));
  }

}
