import { Component, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: [ './history.component.scss' ]
})
export class HistoryComponent implements OnInit {

  constructor(private translate: TranslateService) { }

  ngOnInit(): void { }

  refresh(): void {
    alert('');
  }

}
