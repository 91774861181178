import {Component, ElementRef, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { addCustomCSS } from "@ui5/webcomponents-base/dist/Theming";
addCustomCSS("ui5-dialog",`.ui5-popup-header-root {
  justify-content: left;
  align-items: start;
}`)

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  el: ElementRef;
  type: string;

  constructor(private _element: ElementRef,
              private translate: TranslateService) {
    this.el = _element;
  }

  ngOnInit(): void { }

  show(type, value): void {
    //console.log(type);
    const notification = <HTMLInputElement>document.querySelector('#notificationDialog');
    if(notification) {
      if(type == 'Success') {
        notification['header'][0].innerHTML = '<div class="header success">' +
          '<ui5-icon class="ui5-messagestrip-icon success" name="message-success" ui5-icon=""></ui5-icon>' +
          '<span>'+this.translate.instant('general.success')+'</span></div>';
      } else if(type == 'Error') {
        notification['header'][0].innerHTML = '<div class="header error">' +
          '<ui5-icon class="ui5-messagestrip-icon error" name="message-error" ui5-icon=""></ui5-icon>' +
          '<span>'+this.translate.instant('general.error')+'</span></div>';
      } else if(type == 'Warning') {
        notification['header'][0].innerHTML = '<div class="header warning">' +
          '<ui5-icon class="ui5-messagestrip-icon warning" name="message-warning" ui5-icon=""></ui5-icon>' +
          '<span>'+this.translate.instant('general.warning')+'</span></div>';
      } else {
        notification['header'][0].innerHTML = '<div class="header info">' +
          '<ui5-icon class="ui5-messagestrip-icon info" name="message-information" ui5-icon=""></ui5-icon>' +
          '<span>'+this.translate.instant('general.info')+'</span></div>';
        if(type == 'Confirm') {
          notification['footer'][0].innerHTML =
            '<ui5-button class="margin-btn" design="Emphasized" (click)="confirm()">OK</ui5-button>' +
            '<ui5-button  class="margin-btn" design="Transparent" (click)="hide()">Cancelar</ui5-button>';
        }
      }
      notification['content'][0].innerText = value;
      // @ts-ignore
      notification.show();
    }
  }

  confirm(): void {

  }

  hide(): void {
    const notification = <HTMLInputElement>document.querySelector('#notificationDialog');
    if(notification) {
      // @ts-ignore
      notification.close();
    }
  }
}
