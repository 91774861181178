<ui5-dialog #alertDialog id="alertDialog" class="notificationDialog">
  <div class="dialog-header" slot="header">
    <div class="header info">
      <ui5-icon class="ui5-messagestrip-icon info" name="message-information" ui5-icon=""></ui5-icon>
      <span>{{'general.confirm' | translate}}</span>
    </div>
  </div>
  <div class="dialog-content"></div>
  <div class="dialog-footer">
    <ui5-button class="margin-btn" design="Transparent" (click)="message.noFn()">{{'general.no' | translate}}</ui5-button>
    <ui5-button design="Emphasized" (click)="message.yesFn()">{{'general.yes' | translate}}</ui5-button>
  </div>
</ui5-dialog>
