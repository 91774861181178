import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { User } from '../models/user';

import { environment } from '../../environments/environment';
import { CryptoJS } from '../services/cryptojs.service';
import { ClientJS } from 'clientjs';

@Injectable()
export class AuthService {
  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private CryptoJS: CryptoJS
  ) {}


  fakeLogin(user: User) {
    if (user.userName !== '' && user.password !== '' ) {
      this.loggedIn.next(true);
      localStorage.setItem('login', 'Y');
      this.router.navigate(['/']);
    }
  }

  login(user: User,confirm) {
    const client = new ClientJS();
    // Get the client's fingerprint id
    const fingerprint = client.getFingerprint();

    const encPassword = this.CryptoJS.set(environment.keytoDecrypt,user.password);

    return this.httpClient.post<any>(environment.apiUrl + '/' + environment.servicePath +
      '/SitFramework/Login.xsjs?cmd=Login', {
      'username': user.userName,
      'password': encPassword,
      'usertype': 'Employee',
      'database': environment.database,
      'n': fingerprint,
      'c': confirm
    });
  }

  encrypt(password) {
    const encPassword = this.CryptoJS.set(environment.keytoDecrypt,password);
    return encPassword;
  }

  decrypt(password) {
    const decPassword = this.CryptoJS.get(environment.keytoDecrypt,password);
    return decPassword;
  }

  setLogin() {
    this.loggedIn.next(true);
  }

  setLogout() {
    this.loggedIn.next(false);
  }

  forceLogout() {
    this.loggedIn.next(false);
    localStorage.setItem('login', 'N');
    this.router.navigate(['/login'], {
      queryParams: {
        returnUrl: (this.router.url ? this.router.url.replace(/%23/g, "#") : '')
      }
    });
  }

  logout() {
    return this.httpClient.post<any>(environment.apiUrl + '/' + environment.servicePath +
      '/SitFramework/Login.xsjs?cmd=Logout', {
      'fiori_token': localStorage.getItem(environment.authTokenKey),
      'database': environment.database,
      'userid': localStorage.getItem('userid')
    });
  }
}
