import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DatatableComponent , ColumnMode, SelectionType } from '@swimlane/ngx-datatable';

import { ApiService } from '../../services/api.service';
import { AlertService } from '../../services/alert.service';
import { LoadingComponent } from '../../shared/dialogs/loading/loading.component';
import { NotificationComponent } from '../../shared/dialogs/notification/notification.component';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-tasks',
  templateUrl: './cards.component.html',
  styleUrls: [ './cards.component.scss' ]
})
export class CardsComponent implements OnInit, AfterViewInit {
  @ViewChild('searchInput') searchInputEl: ElementRef;
  @ViewChild('allRowsCheck') allRowsCheckEl: ElementRef;
  @ViewChild('downloadBtn') downloadBtnEl: ElementRef;
  @ViewChild('datatable') table: any;
  rows = [];
  selected = [];
  filteredData = [];
  columnsWithSearch : string[] = [];
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private fb: FormBuilder,
              private breakpointObserver: BreakpointObserver,
              private loading: LoadingComponent,
              private notification: NotificationComponent,
              private translate: TranslateService,
              private alertDialog: AlertService) { }

  ngOnInit(): void {
    const lang = this.translate.currentLang;
    this.checkLogin();
  }

  ngAfterViewInit(): void {
    this.breakpointObserver.observe([
      '(max-width: 960px)'
    ]).subscribe(result => {
      if (result.matches) {
        this.table.rowDetail.expandAllRows();
      } else {
        this.table.rowDetail.collapseAllRows();
      }
    });
  }

  updateFilter(event): void {
    let filter = event.target.value.toLowerCase();
    // @ts-ignore
    this.rows = this.filteredData.filter(item => {
      for (let i = 0; i < this.columnsWithSearch.length; i++) {
        let colValue = item[this.columnsWithSearch[i]] ;
        if (!filter || (!!colValue && colValue.toString().toLowerCase().indexOf(filter) !== -1)) {
          return true;
        }
      }
    });
    // TODO - whenever the filter changes, always go back to the first page
    //this.table.offset = 0;
  }

  onSelect({ selected }): void {
    //console.log('Select Event', selected, this.selected);
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  onActivate(event): void {
    //console.log('Activate Event', event);
  }

  onSelectRow(row): void {
    //console.log(row);
  }

  remove(): void {
    this.selected = [];
  }

  rerender(): void {
    this.rows = [];
    this.searchInputEl.nativeElement.value = '';
    //this.allRowsCheckEl.nativeElement.checked = false;
    this.table.sorts = [];
    this.getCards();
  }

  toggleExpandRow(row): void {
    //console.log('Toggled Expand Row!', row);
    this.table.rowDetail.toggleExpandRow(row);
  }

  toggleAllRows(): void {
    const self = this;
    setTimeout(() => {
      if(window.innerWidth > 960) {
        //console.log('collapse');
        self.table.rowDetail.collapseAllRows();
      } else {
        //console.log('expand');
        self.table.rowDetail.expandAllRows();
      }
    },200);
  }

  onDetailToggle(event): void {
    //console.log('Detail Toggled', event);
  }



  getCards(): void {
    this.loading.show();
    this.apiService.getCards()
      .subscribe(data => {
        // @ts-ignore
        this.rows = data;
        if(this.rows && this.rows.length > 0) {
          this.filteredData = this.rows;
          this.columnsWithSearch = Object.keys(this.rows[0]);
          this.toggleAllRows();
        }
      },error => {
        this.loading.hide();
        this.notification.show('Error',JSON.stringify(error['error']));
        console.log(error.error);
      },() => {
        this.loading.hide();
      });
  }


  checkLogin(): void {
    this.loading.show();
    this.apiService.checkLogin(moment().format('YYYY-MM-DD'))
      .subscribe(data => {
        if(!data['U_Status'] || data['U_Status'] == "CHECKOUT") {
          this.router.navigate(['/']);
          this.notification.show('Info','Efectue o Checkin!');
        } else {
          setTimeout(() => {
            this.getCards();
          },100);
        }
      },error => {
        this.loading.hide();
        this.notification.show('Error',JSON.stringify(error['error']));
        console.log(error.error);
      },() => {
        //this.loading.hide();
      });
  }



}
