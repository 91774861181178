import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DatatableComponent , ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { addCustomCSS } from "@ui5/webcomponents-base/dist/Theming";

import { ApiService } from '../../services/api.service';
import { AlertService } from '../../services/alert.service';
import { LoadingComponent } from '../../shared/dialogs/loading/loading.component';
import { NotificationComponent } from '../../shared/dialogs/notification/notification.component';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
addCustomCSS("ui5-badge",`.ui5-badge-root { width: 101%; }`)

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: [ './tasks.component.scss' ]
})
export class TasksComponent implements OnInit, AfterViewInit {
  @ViewChild('searchInput') searchInputEl: ElementRef;
  @ViewChild('allRowsCheck') allRowsCheckEl: ElementRef;
  @ViewChild('downloadBtn') downloadBtnEl: ElementRef;
  @ViewChild('popoverMaps') popoverMapsEl: ElementRef;
  @ViewChild('datePickerStart') dateStartEl: ElementRef;
  @ViewChild('datePickerEnd') dateEndEl: ElementRef;
  @ViewChild('dialogViewer') dialogViewerEl: ElementRef;
  @ViewChild('dialogProcesses') dialogProcessesEl: ElementRef;
  @ViewChild('dialogTrailers') dialogTrailersEl: ElementRef;
  @ViewChild('dialogTrailerState') dialogTrailerStateEl: ElementRef;
  @ViewChild('strap') strapEl: ElementRef;
  @ViewChild('rulers') rulersEl: ElementRef;
  @ViewChild('datatable') table: any;
  carPlate: string;
  trailerPlate: string;
  process: any;
  processes = [];
  trailers = [];
  searchProcess: string;
  searchTrailer: string;
  PidsString: string;
  rows = [];
  selected = [];
  filteredData = [];
  columnsWithSearch : string[] = [];
  reports: any;
  configs: any;
  filePdf: any;
  formTrailerState: FormGroup;
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  localChange = [
    { Code: "VAL", Name: "Valado do Frades" },
    { Code: "VAG", Name: "Vagos" },
    { Code: "CAR", Name: "Carregado" },
    { Code: "CTM", Name: "CT Pinto Madrid" },
    { Code: "BRS", Name: "British Steel" },
    { Code: "ALR", Name: "Algarve Removals" },
    { Code: "NOT", Name: 'Outro' }
  ];

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private fb: FormBuilder,
              private breakpointObserver: BreakpointObserver,
              private loading: LoadingComponent,
              private notification: NotificationComponent,
              private translate: TranslateService,
              private alertDialog: AlertService) { }

  ngOnInit(): void {
    const lang = this.translate.currentLang;
    this.PidsString = this.route.snapshot.paramMap.get('id');
    this.checkLogin();
    this.formTrailerState = this.fb.group({
      LocalChange: ['VAL', Validators.required],
      NotherLocal: [''],
      Strap: ['', Validators.required],
      Rulers: ['', Validators.required],
      Canvas: [false],
      Tyres: [false],
      Trailer: [true],
      Observations: ['']
    });

    /*const self = this;
    setTimeout(function() {
      self.openTrailerState();
    }, 1000);*/

  }

  ngAfterViewInit(): void {
    this.breakpointObserver.observe([
      '(max-width: 960px)'
    ]).subscribe(result => {
      if (result.matches) {
        this.table.rowDetail.expandAllRows();
      } else {
        this.table.rowDetail.collapseAllRows();
      }
    });

    this.downloadBtnEl.nativeElement.addEventListener('click', event =>
      this.popoverMapsEl.nativeElement.showAt(event.target));

    this.downloadBtnEl.nativeElement.addEventListener('arrow-click', event =>
      this.popoverMapsEl.nativeElement.showAt(event.target));
  }

  updateFilter(event): void {
    let filter = event.target.value.toLowerCase();
    // @ts-ignore
    this.rows = this.filteredData.filter(item => {
      for (let i = 0; i < this.columnsWithSearch.length; i++) {
        let colValue = item[this.columnsWithSearch[i]] ;
        if (!filter || (!!colValue && colValue.toString().toLowerCase().indexOf(filter) !== -1)) {
          return true;
        }
      }
    });
    // TODO - whenever the filter changes, always go back to the first page
    //this.table.offset = 0;
  }

  onSelect({ selected }): void {
    //console.log('Select Event', selected, this.selected);
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  onActivate(event): void {

    if(event.type == 'click') {
      //console.log('Activate Event',  event);
      this.router.navigate(['/tasks', event['row']['DocEntryRes'],
        this.process['DocEntry'],
        this.process['U_Vehicle'],
        this.process['U_LicenceTruck'],
        this.process['U_BaseType'],
        this.process['DelType'],
        this.process['Type'],
        event['row']['needCarga'] == 'Y' ? 'C' : 'D',
        event['row']['LineCounter']
      ]);
    }
  }

  onSelectRow(row): void {
     console.log('Activate Row',  row);
      /*let loadUnload = '';
      if(this.process['Type'] == 'IMP' ||
        this.process['DelType'] == 'FTL' ||
        this.process['U_BaseType'] == 'NOX_ORSV') {
        loadUnload = 'C';
        if(this.process['Type'] == 'NAC' && this.process['DelType'] == 'REC') {} else { loadUnload = 'D'; }
      }*/
      this.router.navigate(['/tasks', row['DocEntryRes'],
        this.process['DocEntry'],
        this.process['U_Vehicle'],
        this.process['U_LicenceTruck'],
        this.process['U_BaseType'],
        this.process['DelType'],
        this.process['Type'],
        row['needCarga'] == 'Y' ? 'C' : 'D',
        row['LineCounter']
        ]);
  }

  remove(): void {
    this.selected = [];
  }

  rerender(): void {
    this.rows = [];
    this.searchInputEl.nativeElement.value = '';
    //this.allRowsCheckEl.nativeElement.checked = false;
    this.table.sorts = [];
    this.getTasks(this.process);
  }

  toggleExpandRow(row): void {
    //console.log('Toggled Expand Row!', row);
    this.table.rowDetail.toggleExpandRow(row);
  }

  toggleAllRows(): void {
    const self = this;
    setTimeout(() => {
      if(window.innerWidth > 960) {
        //console.log('collapse');
        self.table.rowDetail.collapseAllRows();
      } else {
        //console.log('expand');
        self.table.rowDetail.expandAllRows();
      }
    },200);
  }

  onDetailToggle(event): void {
    //console.log('Detail Toggled', event);
  }

  printMaps(type = null): void {
    this.popoverMapsEl.nativeElement.close();
    if(type) {
      if(!this.reports || !this.reports['MapProcess']) {
        this.notification.show('Error','Relatório inválido');
        return;
      }

      this.filePdf = {
        src: '',
        name: 'mapa.pdf'
      }
      this.dialogViewerEl.nativeElement.show();

      const params = [{Name: "DocEntry", Value: this.rows[0]['U_BaseEntry'].toString()},
        {Name:"LineId", Value:"-1"},{Name:"ObjectType",Value:'NOX_OGRP' }];
      this.loading.show();
      this.apiService.getMaps(this.reports['MapProcess']['U_Value'], params)
        .subscribe(data => {
          this.filePdf = {
            src: data,
            name: 'mapa_entrega_recolha.pdf'
          }
          this.dialogViewerEl.nativeElement.show();
        },error => {
          this.loading.hide();
          this.notification.show('Error',JSON.stringify(error['error']));
          console.log(error.error);
        },() => {
          this.loading.hide();
        });
    } else {
      if(!this.reports || !this.reports['MapaMotoristaProcesso']) {
        this.notification.show('Error','Relatório inválido');
        return;
      }

      this.filePdf = {
        src: '',
        name: 'mapa.pdf'
      }
      this.dialogViewerEl.nativeElement.show();
      const params = [{Name: "Processo", Value: this.process['DocEntry'].toString()}];
      this.loading.show();
      this.apiService.getMaps(this.reports['MapaMotoristaProcesso']['U_Value'], params)
        .subscribe(data => {
          this.filePdf = {
            src: data,
            name: 'mapa_motorista.pdf'
          }
          this.dialogViewerEl.nativeElement.show();
        },error => {
          this.loading.hide();
          this.notification.show('Error',JSON.stringify(error['error']));
          console.log(error.error);
        },() => {
          this.loading.hide();
        });
    }
  }

  closeDialogMaps(): void {
    this.dialogViewerEl.nativeElement.close();
  }

  openProcesses(): void {
    this.searchProcess = null;
    this.dialogProcessesEl.nativeElement.show();
  }

  closeDialogProcesses(): void {
    this.dialogProcessesEl.nativeElement.close();
  }

  selectProcess(process): void {
    const self = this;
    if(process?.CheckStatus == 0 && (process?.DelType == 'REC' || process?.DelType == 'FTL')){
      self.rows = [];
      window.history.pushState({}, '',`/tasks`);
      self.alertDialog.confirm(self.translate.instant('tasks.trailerConfirm') +process?.Reboque+"?",
        function(){
          self.openTrailerState();
          self.rows = [];
          window.history.pushState({}, '',`/tasks/${process['DocEntry']}`);
          self.getTasks(process);
        },function(){
          self.process = process;
          self.getTrailers();
        });
    } else {
      self.rows = [];
      window.history.pushState({}, '',`/tasks/${process['DocEntry']}`);
      self.getTasks(process);
    }
  }

  openTrailers(): void {
    this.searchTrailer = null;
    this.dialogTrailersEl.nativeElement.show();
  }

  closeDialogTrailers(): void {
    this.dialogTrailersEl.nativeElement.close();
  }

  openTrailerState(): void {
    this.formTrailerState.patchValue({
      LocalChange: 'VAL',
      Trailer: true,
    });
    this.dialogTrailerStateEl.nativeElement.show();
  }

  closeDialogTrailerState(): void {
    const Strap = this.strapEl.nativeElement;
    const Rulers = this.rulersEl.nativeElement;
    Strap.setAttribute('value-state', '');
    Rulers.setAttribute('value-state', '');
    this.formTrailerState.reset();
    this.dialogTrailerStateEl.nativeElement.close();
  }

  saveTrailerState(): void {
    const controls = this.formTrailerState.controls;
    const Strap = this.strapEl.nativeElement;
    const Rulers = this.rulersEl.nativeElement;
    Strap.setAttribute('value-state', '');
    Rulers.setAttribute('value-state', '');
    /** check form */
    console.log(this.formTrailerState);
    if (this.formTrailerState.invalid) {
      Object.keys(controls).forEach(controlName => {
        controls[controlName].markAsTouched()
        if (controls[controlName].invalid) {
          try {eval(controlName).setAttribute('value-state', 'Error')} catch (e) {}
        }
      });
      return;
    }

    const formTrailer = {
      VehicleCode: this.process['U_Vehicle'],
      Trailer: this.process['U_LicenceTruck'],
      Reboque: this.process['Reboque'],
      LocalChange: controls['LocalChange'].value,
      NotherLocal: controls['NotherLocal'].value,
      Strap: controls['Strap'].value,
      Rulers: controls['Rulers'].value,
      Canvas: controls['Canvas'].value,
      YesCanvas: (controls['Canvas'].value === true),
      NoCanvas: (controls['Canvas'].value === false),
      Tyres: controls['Tyres'].value,
      YesTyres: (controls['Tyres'].value === true),
      NoTyres: (controls['Tyres'].value === false),
      Trailer_: controls['Trailer'].value,
      YesTrailer: (controls['Trailer'].value === true),
      NoTrailer: (controls['Trailer'].value === false),
      Observations: controls['Observations'].value,
    };

    this.loading.show();
    this.apiService.saveChangeTrailer(formTrailer).subscribe(data => {
      this.notification.show('Success','Submetido com sucesso');
      this.sendEmailTrailer(formTrailer);
      this.formTrailerState.reset();
      this.closeDialogTrailerState();
    },error => {
      this.loading.hide();
      this.notification.show('Error',JSON.stringify(error['error']));
      console.log(error.error);
    },() => {
      this.loading.hide();
    });


  }

  async sendEmailTrailer(trailer) {
    try {
      await this.getSysConfigs();
    } catch (e) {
      console.log(e);
    }

    if (this.configs && this.configs['emailsToSend']) {
      const email = {
        to:  this.configs['emailsToSend'],
        cc: '',
        bbc: '',
        subject: 'Checkin - Motorista',
        bodyemail: ''
      };
      email.bodyemail = '<p>Exmo. Colaborador,</p><p>O motorista ' + localStorage.getItem('user') + ' efetuou checkin com os seguintes dados: </p>';
      email.bodyemail += ' <p>Veiculo: ' + this.process['U_CarPlate'] + '  </p>';
      email.bodyemail += ' <p>Reboque: ' + trailer.Reboque + '  </p>';
      email.bodyemail += ' <p>Local Troca: ' + trailer.LocalChange + '  </p>';
      email.bodyemail += ' <p>Nº Cintas: ' + trailer.Strap + '  </p>';
      email.bodyemail += ' <p>Nº Réguas Travamento: ' +trailer.Rulers + '  </p>';
      email.bodyemail += ' <p>Lona Com Cortes: ' + (trailer.Canvas === true ? this.translate.instant('tasks.yes') : this.translate.instant('tasks.no')) + '  </p>';
      email.bodyemail += ' <p>Pneus com danos visiveis: ' + (trailer.Tires === true ? this.translate.instant('tasks.yes') : this.translate.instant('tasks.no'))+ '  </p>';
      email.bodyemail += ' <p>Reboque limpo: ' + (trailer.Trailer_ === true ? this.translate.instant('tasks.yes') : this.translate.instant('tasks.no')) + '  </p>';

      if(trailer.Observation)
        email.bodyemail += ' <p>Observações: ' + trailer.Observation + '  </p>';

      this.sendEmail(email);
    }
  }

  selectTrailer(trailer): void {
    const self = this;
    const process = {
      DocEntry: self.process['DocEntry'],
      Vehicle: self.process['U_Vehicle'],
      Trailler: trailer['Code'],
      OldTrailler: self.process['Reboque'],
      NewTrailler: trailer['U_CarPlate'],
      DriverName: localStorage.getItem('user')
    }

    self.alertDialog.confirm(self.translate.instant('tasks.trailerConfirm') +trailer['U_CarPlate']+"?",
      function(){
      self.updateProcess(process);
    },function(){});

  }

  getWidth(): number {
    return window.innerWidth;
  }

  getGlobalReports(): void {
    //this.loading.show();
    this.apiService.getGlobalReports()
      .subscribe(data => {
        this.reports = data;
      },error => {
        this.loading.hide();
        //this.notification.show('Error',JSON.stringify(error['error']));
        console.log(error.error);
      },() => {
        //this.loading.hide();
      });
  }

  async getSysConfigs() {
    this.configs = null;
    try {
      this.configs = await this.apiService.getSysConfigsSync();
    } catch (e) { console.log(e); }
    //this.loading.show();
    /*this.apiService.getSysConfigs()
      .subscribe(data => {
        this.configs = data;
      },error => {
        this.loading.hide();
        //this.notification.show('Error',JSON.stringify(error['error']));
        console.log(error.error);
      },() => {
        //this.loading.hide();
      });*/
  }

  checkLogin(): void {
    this.loading.show();
    this.apiService.checkLogin(moment().format('YYYY-MM-DD'))
      .subscribe(data => {
        if(!data['U_Status'] || data['U_Status'] == "CHECKOUT") {
          this.router.navigate(['/']);
          this.notification.show('Info','Efectue o Checkin!');
        } else {
          // @ts-ignore
          this.carPlate = data.MatriculaVeiculo;
          // @ts-ignore
          this.trailerPlate = data.MatriculaReboque;
          setTimeout(() => {
            this.getProcesses();
            this.getGlobalReports();
            //this.getSysConfigs();
          },100);
        }
      },error => {
        this.loading.hide();
        this.notification.show('Error',JSON.stringify(error['error']));
        console.log(error.error);
      },() => {
        this.loading.hide();
      });
  }

  getTrailers(): void {
    this.loading.show();
    this.apiService.getTrailers()
      .subscribe(data => {
        // @ts-ignore
        this.trailers = data;
        this.openTrailers();
      },error => {
        this.loading.hide();
        this.notification.show('Error',JSON.stringify(error['error']));
        console.log(error.error);
      },() => {
        this.loading.hide();
      });
  }

  updateProcess(process): void {
    this.loading.show();
    this.apiService.updateProcess(process)
      .subscribe(data => {
        this.rows = [];
        this.closeDialogTrailers();
        this.getProcesses(false, true);
        this.openTrailerState();
      },error => {
        this.loading.hide();
        this.notification.show('Error',JSON.stringify(error['error']));
        console.log(error.error);
      },() => {
        this.loading.hide();
      });
  }

  getProcesses(request = false, trailerChange = false): void {
    this.loading.show();
    this.apiService.getProcesses()
      .subscribe(data => {
        // @ts-ignore
        this.processes = data;

        //console.log(idsString);
        if(!request) {
          let process = null;
          for(let i=0; i < this.processes.length; i++) {
            // @ts-ignore
            if(this.processes[i].DocEntry == this.PidsString) {
              // @ts-ignore
              process = this.processes[i];
            }
          }
          if(process && !trailerChange) {
            this.getTasks(process);
          } else if(trailerChange) {
            this.getTasks(this.process);
          } else {
            this.openProcesses();
          }
        } else {
          this.openProcesses();
        }
      },error => {
        this.loading.hide();
        this.notification.show('Error',JSON.stringify(error['error']));
        console.log(error.error);
      },() => {
        this.loading.hide();
      });
  }

  getTasks(process): void {
    let append = false;
    this.process = process;
    const body = {
      DocEntry:  process['DocEntry'],
      CarCode: process['U_Vehicle']
    }
    //if(process['Type'] == 'IMP' || process['DelType'] == 'FTL' || process['U_BaseType'] == 'NOX_ORSV') {
    if(process['Type'] == 'IMP' ||  process['DelType'] == 'FTL' || process['U_BaseType'] == 'NOX_ORSV'
      && (process['Type'] !== 'NAC' && process['DelType'] !== 'EDI')) {
      body['Type'] = process['Type'];
      body['LoadUnload'] = 'C';
      //this._getTasks(body);
      //if(process['Type'] != 'NAC' && process['DelType'] != 'REC') {
      if((process['Type'] == 'NAC' || process['Type'] == 'IMP') && process['DelType'] == 'REC'){
        // TODO
      }else {
        append = true;
        /*body['LoadUnload'] = 'D';
        setTimeout(() => {
          this._getTasks(body,true);
        },200);*/
      }
      this._getTasks(body, append);
    } else {
      body['DeliveryType'] = process['DelType'];
      body['BaseType'] = process['U_BaseType'];
      this._getTasks(body);
    }
  }


  _getTasks(body,append = false): void {
    this.loading.show();
    this.apiService.getTasks(body)
      .subscribe(r => {
        this.createTasksList(r);
        if(append) {
          setTimeout(() => {
            this.loading.show();
            body['LoadUnload'] = 'D';
            this.apiService.getTasks(body)
              .subscribe(r2 => {
                this.createTasksList(r2);
              },error => {
                this.loading.hide();
                this.notification.show('Error',JSON.stringify(error['error']));
                console.log(error.error);
              },() => {
                this.loading.hide();
              });
          },200);
        }
      },error => {
        this.loading.hide();
        this.notification.show('Error',JSON.stringify(error['error']));
        console.log(error.error);
      },() => {
        this.loading.hide();
      });
  }

  createTasksList(r): void {
    //isto é por causa da importação
    let clenaModel = true;
    if(this.rows && this.rows.length > 0){
      for(var o=0;o<this.rows.length;o++){
        if(this.rows[o].DocEntryPro ==  r[0].DocEntryPro){
          clenaModel = false;
          break;
        }
      }
    }
    let newData = [];
    // @ts-ignore
    for(let k=0;k < r.length;k++) {
      let row = r[k];
      //row.LineCounter = k;
      if(row.U_DeliveryType == 'EDI' || row.U_DeliveryType == 'DIR'){//"Entrega Direta"
        row.DeliveryType = 'Entrega Direta';
      }else if(row.U_DeliveryType == 'ETE' ){//ETE --> terminal
        row.DeliveryType = 'Entrega Terminal Próprio';
      }else if(row.U_DeliveryType == 'REC'){//REC--> recolha
        row.DeliveryType = 'Recolha';
      }else if(row.U_DeliveryType == 'EAG' || row.U_DeliveryType == 'AGN' ){//EAG--> Agent
        row.DeliveryType = 'Entrega Agente';
      }else if(row.U_DeliveryType == 'FTL' || row.U_DeliveryType == 'EDI'){//FTL--> Direto
        row.DeliveryType = 'Direto-Direto';
      }
      if(row.U_Type == 'IMP'){
        row.Type = 'Importação';
      }else if(row.U_Type == 'EXP'){
        row.Type = 'Exportação';
      }else{
        row.Type = 'Nacional';
      }
      if(row.U_Type == 'NAC'){
        if(row.RecEntCode == 'R' && row.WhsName){
          row.visibleTypeWhs = true;
        }else{
          row.visibleTypeWhs = false;
        }
      }else if( row.WhsName){
        row.visibleTypeWhs = true;
      }
      if((row.U_Type == 'NAC' && row.RecEntCode == 'R') || row.U_Type !== 'NAC'){
        row.visibleDeliveryType = true;
      }else{
        row.visibleDeliveryType = true;
      }
      if(row.U_Type == 'NAC'){
        row.U_DeliveryType = 'ETE';
      }
      row.valid = true;
      if(row.U_DeliveryType == 'FTL'){
        //significa que é direto e que precisa tb de recolha
      }else if(newData && newData.length == 0){  //aqui é nacional ou exportacaos
        row.valid = false;
        newData.push(row);
      }else{
        let datesMax = [], datesMin = [];
        for(let m=0;m<newData.length;m++){
          let rowNew = newData[m];
          //tudo ok, até à importacao:
          if(row.RecEntCode == 'R'){
            //expedidor igual?
            if(rowNew.RecEntCode == row.RecEntCode && rowNew.ExpCode == row.ExpCode && rowNew.ExpAdd == row.ExpAdd){
              row.valid = false;
              //aqui temos que ver as datas; agrupar o destinatário e afins
              rowNew.DocEntryRes = rowNew.DocEntryRes + '#' + row.DocEntryRes;
              if(row.Status !== 'End') rowNew.Status = row.Status || '';
              datesMin.push(new Date(rowNew.PrevDateCar));
              let minDate = new Date(Math.min.apply(null, datesMin));
              rowNew.PrevDateCar = moment(minDate).format("YYYY-MM-DD HH:mm:ss");
              //rowNew.PrevDateCar = moment(minDate).format("YYYY-MM-DD");
              //datesMax.push(new Date(rowNew.PrevDateDesc));
              //let maxDate = new Date(Math.max.apply(null, datesMax));
              //rowNew.PrevDateCar = moment(maxDate).format("YYYY-MM-DD");
              break;
            }
          }
          if(row.RecEntCode == 'E') {
            //destinatário igual? DestAdd: -1     DestCode: "F1005"
            if(rowNew.RecEnt == row.RecEnt && rowNew.DestCode == row.DestCode && rowNew.DestAdd == row.DestAdd){
              row.valid = false;
              //aqui temos que ver as datas; agrupar o destinatário e afins
              rowNew.DocEntryRes = rowNew.DocEntryRes + '#' + row.DocEntryRes;
              if(row.Status !== 'End') rowNew.Status = row.Status || '';
              //datesMin.push(new Date(rowNew.PrevDateCar));
              //let minDate = new Date(Math.min.apply(null, datesMin));
              //rowNew.PrevDateCar = moment(minDate).format("YYYY-MM-DD");
              datesMax.push(new Date(rowNew.PrevDateDesc));
              let maxDate = new Date(Math.max.apply(null, datesMax));
              rowNew.PrevDateCar = moment(maxDate).format("YYYY-MM-DD HH:mm:ss");
              //rowNew.PrevDateCar = moment(maxDate).format("YYYY-MM-DD");
              break;
            }
          }
        }
      }
      if(row.valid){
        newData.push(row);
      }
    }

    // Grupagem Bug Fix
    for(let i=0;i<newData.length;i++){
      newData[i].LineCounter = i;
    }
    /*if(append) {
      for(let i=0;i<newData.length;i++){//significa que é importacao
        this.rows.push(newData[i]);
      }
    } else {*/
    if(clenaModel) {
      this.rows = newData;
    } else {
      for(let i=0;i<newData.length;i++) {//significa que é importacao
        this.rows.push(newData[i]);
      }
    }
    //}
    // @ts-ignore
    //this.rows = r;
    for (let i in this.rows) {
      if(this.rows[i]['needCarga'] == 'Y') {
        this.rows[i]['Name'] = this.rows[i]['LocalNameExp'];
        this.rows[i]['LocalAddress'] = this.rows[i]['LocalAddressExp'];
        this.rows[i]['PrevDate'] = this.rows[i]['PrevDateCar'];
      } else {
        this.rows[i]['Name'] = this.rows[i]['LocalName'];
        this.rows[i]['LocalAddress'] = this.rows[i]['LocalAddress'];
        this.rows[i]['PrevDate'] = this.rows[i]['PrevDateDesc'];
      }
    }
    this.rows = [...this.rows];
    //console.log(this.rows);
    if(this.rows && this.rows.length > 0) {
      this.filteredData = this.rows;
      this.columnsWithSearch = Object.keys(this.rows[0]);
      this.toggleAllRows();
    }
    this.closeDialogProcesses();
  }

  sendEmail(data): void {
    this.loading.show();
    this.apiService.sendEmail(data)
      .subscribe(data => {
        console.log(data);
      },error => {
        this.loading.hide();
        this.notification.show('Error',JSON.stringify(error['error']));
        console.log(error.error);
      },() => {
        this.loading.hide();
      });
  }

}
