<ui5-page id="page" class="page page-padding page-dashboard" background-design="Solid" floating-footer show-footer>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px">
    <div fxFlex="33">
      <ui5-card heading="{{'dashboard.truckdriver' | translate}}" subheading="{{user}}" class="small">
        <!--<img src="/assets/images/man_avatar_1.png" slot="avatar" />-->
        <ui5-card-header slot="header" titleText="{{'dashboard.truckdriver' | translate}}" subtitleText="{{user}}">
          <img src="/assets/images/man_avatar_2.png" slot="avatar" />
          <!--<ui5-avatar icon="employee" slot="avatar" size="S"></ui5-avatar>-->
        </ui5-card-header>
        <ui5-list separators="Inner" >
          <!--<ui5-li icon="visits" (click)="onNavigate('menu-checkinout')" icon-end>Check-in/out</ui5-li>-->
          <ui5-li icon="mileage" (click)="onNavigate('menu-refuelling')" icon-end>{{'menu.refulling' | translate}}</ui5-li>
          <ui5-li icon="activities" (click)="onNavigate('menu-tasks')" icon-end>{{'menu.tasks' | translate}}</ui5-li>
          <ui5-li icon="add-equipment" (click)="onNavigate('menu-maintenance')" icon-end>{{'menu.maintenance' | translate}}</ui5-li>
          <ui5-li icon="credit-card" (click)="onNavigate('menu-cards')" icon-end>{{'menu.cards' | translate}}</ui5-li>
        </ui5-list>
      </ui5-card>
    </div>
    <div fxFlex="34">
      <ui5-card heading="{{'menu.tasks' | translate}}" subheading="{{'dashboard.pending' | translate}}"
                status="Total {{pendTasks?.length}}" class="small">
        <ui5-card-header slot="header" titleText="{{'menu.tasks' | translate}}" subtitleText="{{'dashboard.pending' | translate}}"
                         status="{{pendTasks?.length}} {{ (tasks?.length > 0) ? 'de ' + tasks.length : ''}}">
        </ui5-card-header>
        <ui5-list separators="None" class="content-padding">
          <ui5-li-custom *ngFor="let task of pendTasks | slice:0:3" (click)="onNavigateTask(task['DocEntryRes'])">
            <div class="item">
              <div class="item-content-begin">
                <ui5-title level="H5" class="item-content-begin-title">
                  {{(task['needCarga'] == 'Y') ? task['LocalNameExp'] : task['LocalName'] }}
                </ui5-title>
                <ui5-label>{{(task['needCarga'] == 'Y') ? task['PrevDateCar'] : task['PrevDateDesc']}}</ui5-label>
              </div>
              <div class="item-content-end">
                <span *ngIf="task['needCarga'] == 'Y'" class="status-warning">{{'dashboard.load' | translate}}</span>
                <span *ngIf="task['needCarga'] != 'Y'" class="status-success">{{'dashboard.unload' | translate}}</span>
              </div>
            </div>
          </ui5-li-custom>
          <ui5-li-custom *ngIf="pendTasks?.length == 0">
            <div class="item">
              <div class="item-content-begin">
                <ui5-title level="H5" class="item-content-begin-title">Sem Tarefas</ui5-title>
                <ui5-label></ui5-label>
              </div>
              <div class="item-content-end">
              </div>
            </div>
          </ui5-li-custom>
          <!--<ui5-li-custom>
            <div class="item">
              <div class="item-content-begin">
                <ui5-title level="H5" class="item-content-begin-title">Avantel</ui5-title>
                <ui5-label>ID234522566-D44</ui5-label>
              </div>
              <div class="item-content-end">
                <span class="status-warning">27.25K EUR</span>
              </div>
            </div>
          </ui5-li-custom>
          <ui5-li-custom>
            <div class="item">
              <div class="item-content-begin">
                <ui5-title level="H5" class="item-content-begin-title">Avantel</ui5-title>
                <ui5-label>ID234522566-D44</ui5-label>
              </div>
              <div class="item-content-end">
                <span class="status-error">27.25K EUR</span>
              </div>
            </div>
          </ui5-li-custom>-->
        </ui5-list>
      </ui5-card>
    </div>
    <div fxFlex="33">
      <ui5-card heading="{{'dashboard.truck' | translate}}" subheading="Renault" class="small">
        <!--img src="/assets/images/truck.jpg" slot="avatar" />-->
        <ui5-card-header slot="header" titleText="{{'dashboard.truck' | translate}}"
                         subtitleText="{{(details?.U_Brand) ? details?.U_Brand : '-'}}">
          <img src="/assets/images/truck3.jpg" slot="avatar" />
          <!--<ui5-avatar icon="shipping-status" slot="avatar" size="S"></ui5-avatar>-->
        </ui5-card-header>

        <div class="content content-padding">
          <ui5-title level="H5" style="padding-bottom: 1rem;">{{'dashboard.details' | translate}}</ui5-title>

          <div class="content-group">
            <ui5-label>{{'dashboard.model' | translate}}</ui5-label>
            <ui5-title level="H6">{{(details?.U_Model) ? details?.U_Model : '-'}}</ui5-title>
          </div>
          <div class="content-group">
            <ui5-label>{{'dashboard.plate' | translate}}</ui5-label>
            <ui5-title level="H6">{{(details?.U_Alias) ? details?.U_Alias : '-'}}</ui5-title>
          </div>
          <div class="content-group">
            <ui5-label>Kms</ui5-label>
            <ui5-title level="H6">{{(details?.U_Odometer) ? details?.U_Odometer : '-'}}</ui5-title>
          </div>
        </div>
      </ui5-card>
    </div>
  </div>

  <ui5-card heading="{{'menu.tasks' | translate}}" subheading="{{'dashboard.time' | translate}}"
             status="{{'dashboard.monthly' | translate}}" class="medium">
    <ui5-card-header slot="header" titleText="{{'menu.tasks' | translate}}" subtitleText="{{'dashboard.time' | translate}}"
                     status="{{'dashboard.monthly' | translate}}">
    </ui5-card-header>
    <div #ContainerRef style="margin: 30px;">
      <ngx-charts-line-chart
        #tasksChart
        [view]="[ContainerRef.offsetWidth, 500]"
        [scheme]="colorScheme"
        [legend]="legend"
        [legendTitle]="legendTitle"
        [legendPosition]="(getWidth() <= 960 ) ? 'below' : 'right'"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxis]="xAxis"
        [yAxis]="yAxis"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [xAxisTickFormatting]="xAxisTickFormatting"
        [timeline]="timeline"
        [results]="taskChart"
        (select)="onSelect($event)"
        (activate)="onActivate($event)"
        (deactivate)="onDeactivate($event)">
      </ngx-charts-line-chart>
    </div>
  </ui5-card>
</ui5-page>


