import {Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import { DatatableComponent , ColumnMode, SelectionType } from '@swimlane/ngx-datatable';

import { ApiService } from '../../services/api.service';
import { AlertService } from '../../services/alert.service';
import { LoadingComponent } from '../../shared/dialogs/loading/loading.component';
import { NotificationComponent } from '../../shared/dialogs/notification/notification.component';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import {Router} from '@angular/router';

@Component({
  selector: 'app-maintenancetdv',
  templateUrl: './maintenancetdv.component.html',
  styleUrls: [ './maintenancetdv.component.scss' ]
})
export class MaintenancetdvComponent implements OnInit, AfterViewInit {
  @ViewChild('searchInput') searchInputEl: ElementRef;
  @ViewChild('allRowsCheck') allRowsCheckEl: ElementRef;
  @ViewChild('downloadBtn') downloadBtnEl: ElementRef;
  @ViewChild('filterBtn') filterBtnEl: ElementRef;
  @ViewChild('popoverFilter') popoverFilterEl: ElementRef;
  @ViewChild('dialogMaintenance') dialogEl: ElementRef;
  @ViewChild('filterSwitch') filterSwitchEl: ElementRef;
  @ViewChild('type') typeEl: ElementRef;
  @ViewChild('types') typesEl: ElementRef;
  @ViewChild('action') actionEl: ElementRef;
  @ViewChild('vehicle') vehicleEl: ElementRef;
  @ViewChild('vehicleType') vehicleTypeEl: ElementRef;
  @ViewChild('datatable') table: any;
  @ViewChild('dialogFiles') dialogFilesEl: ElementRef;
  @ViewChild('dialogPreview') dialogPreviewEl: ElementRef;
  @ViewChild('photo') photoEl: ElementRef;
  @ViewChild('photoCollection') photoCollectionEl: ElementRef;
  @ViewChild('photoFile') photoFileEl: ElementRef;
  formDialog: FormGroup;
  formId: any;
  status: any;
  rows = [];
  rowsAll = [];
  filter: string = 'O';
  maintenances = [];
  selected = [];
  filteredData = [];
  docEntry: number;
  columnsWithSearch : string[] = [];
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  types = [];
  files = [];
  vehiclePlate: string;
  trailerPlate: string;
  vehicleCode: string;
  isPdf: boolean;
  fileViewer: any;

  breakTypes = [];
  maintenanceTypes = [
    { id: '', value: '' },
    { id: 'M', value: 'Manutenção' },
    { id: 'R', value: 'Reparação' },
    { id: 'S', value: 'Substituição' },
    { id: 'O', value: 'Outros' }
  ];


  constructor(private apiService: ApiService,
              private fb: FormBuilder,
              private breakpointObserver: BreakpointObserver,
              private loading: LoadingComponent,
              private notification: NotificationComponent,
              private translate: TranslateService,
              private router: Router,
              private alertDialog: AlertService) { }

  ngOnInit(): void {
    const lang = this.translate.currentLang;
    this.formDialog = this.fb.group({
      type: ['', Validators.required],
      //action: ['', Validators.required],
      action: [''],
      vehicle: ['', Validators.required],
      observations: ['']
    });
    this.checkLogin();
  }

  ngAfterViewInit(): void {
    const self = this;
    self.breakpointObserver.observe([
      '(max-width: 960px)'
    ]).subscribe(result => {
      if (result.matches) {
        self.table.rowDetail.expandAllRows();
      } else {
        self.table.rowDetail.collapseAllRows();
      }
    });
    self.photoFileEl.nativeElement.addEventListener("ui5-change", event => {
      const files = event.target.files;
      if (!files.length) {
        console.log('No files')
      } else {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          //self.uploadFile(file, file.name, file.size, file.type, 'TEMP', 'SIT_ODCO');
          const reader = new FileReader();
          reader.readAsArrayBuffer(file);
          reader.onload = function (event) {
            self.uploadFile(reader.result, file.name, file.size, file.type,
              ((self.formId) ? (self.formId) : 'TEMP_' + localStorage.getItem('userid')),'SIT_OMRQ')
          };
          reader.onerror = function (error) {
            console.log('Erro ao ler a imagem : ', error);
            self.notification.show('Error','Erro ao ler a imagem : '+ JSON.stringify(error));
          };
        }
      }
    });

    self.photoCollectionEl.nativeElement.addEventListener("drop", event => {
      event.preventDefault();
      const files = event.dataTransfer.files;
      // Take the files from the drop event and create <ui5-upload-collection-item> from them
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        //console.log(file.size);
        //self.uploadFile(file, file.name, file.size, file.type, 'TEMP', 'SIT_ODCO');
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = function (event) {
          self.uploadFile(reader.result, file.name, file.size, file.type,
            ((self.docEntry) ? (self.formId) : 'TEMP_' + localStorage.getItem('userid')),'SIT_OMRQ')
        };
        reader.onerror = function (error) {
          console.log('Erro ao ler a imagem : ', error);
          self.notification.show('Error','Erro ao ler a imagem : '+ JSON.stringify(error));
        };
      }
    });

    self.photoCollectionEl.nativeElement.addEventListener("item-delete", event => {
      if(event && event.detail && event.detail.item) {
        this.deleteFile(event.detail.item.title);
      } else {
        this.notification.show('Error','Erro a apagar ficheiro');
      }
    });

    self.filterBtnEl.nativeElement.addEventListener('click', event =>
      self.popoverFilterEl.nativeElement.showAt(event.target));

  }

  updateFilter(event) {
    let filter = event.target.value.toLowerCase();
    // @ts-ignore
    this.rows = this.filteredData.filter(item => {
      for (let i = 0; i < this.columnsWithSearch.length; i++){
        var colValue = item[this.columnsWithSearch[i]] ;
        if (!filter || (!!colValue && colValue.toString().toLowerCase().indexOf(filter) !== -1)) {
          return true;
        }
      }
    });
    // TODO - whenever the filter changes, always go back to the first page
    //this.table.offset = 0;
  }

  onSelect({ selected }) {
    //console.log('Select Event', selected, this.selected);
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  onActivate(event) {
    //console.log('Activate Event', event);
    if(event.type == 'click') {
      //console.log(event.row);
      this.edit(event.row);
      this.dialogEl.nativeElement.show();
    }
  }

  remove() {
    this.selected = [];
  }

  async rerender(): Promise<void> {
    this.searchInputEl.nativeElement.value = '';
    //this.allRowsCheckEl.nativeElement.checked = false;
    this.table.sorts = [];
    await this.getMaintenancesSync();
    if(this.filter) {
      this.rows = this.rows.filter((el) => {
        return el.U_Status == this.filter;
      });
    }
  }

  toggleExpandRow(row) {
    console.log('Toggled Expand Row!', row);
    this.table.rowDetail.toggleExpandRow(row);
  }

  toggleAllRows() {
    const self = this;
    setTimeout(() => {
      if(window.innerWidth > 960) {
        console.log('collapse');
        self.table.rowDetail.collapseAllRows();
      } else {
        console.log('expand');
        self.table.rowDetail.expandAllRows();
      }
    },200);
  }

  onDetailToggle(event) {
    console.log('Detail Toggled', event);
  }

  add(): void {
    const vehicleType = this.vehicleTypeEl.nativeElement;
    if(vehicleType) vehicleType['checked'] = true;

    if(this.maintenances.length > 0) {
      this.docEntry = this.maintenances[0].DocEntry;
    } else {
      this.docEntry = null;
    }
    this.formId = null;
    this.status = null;
    this.formDialog.patchValue({
      vehicle: this.vehiclePlate
    });
    this.getFiles();
    this.dialogEl.nativeElement.show();


  }

  edit(row): void {
    this.docEntry = row['DocEntry'];
    this.formId = row['DocEntry'];
    this.status = row['U_Status'];
    this.formDialog.setValue({
      type: row['U_Type'],
      action: row['U_Action'],
      vehicle: row['U_Vehicle'],
      observations: row['U_Observations']
    });
    this.getFiles();
    this.dialogEl.nativeElement.show();
  }

  removeSelected(): void {
    const self = this;
    if(self.selected.length >0) {
      self.alertDialog.confirm(self.translate.instant('maintenance.confirmremove'), function(){
        self.loading.show();
        /*const remData = {
          U_FioriUser: localStorage.getItem('userid'),
          DocEntry: this.docEntry,
          NOX_MRQ1Collection: this.rows.filter(item => !remIds.includes(item.LineId))
        };*/
        const remIds = [];
        for(let i in self.selected) {
          remIds.push(self.selected[i]['DocEntry'])
        }
        //console.log(remIds);
        //const mantain = self.rows.filter(item => !remIds.includes(item.DocEntry));
        self.apiService.deleteMaintenanceV2(self.docEntry, remIds).subscribe(data => {
          if(data && data['Success'] == true) {
            //console.log(data);
            self.notification.show('Success','Manutenções removidas com sucesso');
            self.rerender();
            self.closeDialog();
          } else {
            self.notification.show('Error',data['Msg']);
          }
        },error => {
          self.loading.hide();
          self.notification.show('Error',JSON.stringify(error['error']));
          console.log(error.error);
        },() => {
          self.loading.hide();
        });
      },function(){});
    } else {
      this.notification.show('Warning','Selecione as linhas a remover');
    }
  }

  setComboValues(event): void {
    //console.log(event);
    this.types = [];
    for (let i = 0; i < event['detail']['items'].length; i++){
      this.types.push(event['detail']['items'][i]['value']);
    }
    if(this.types.length > 0) {
      this.formDialog.controls['type'].setValue(this.types.toString())
    }
  }

  resetComboBox(): void {
    this.types = [];
    const types = this.typesEl.nativeElement;
    const items = types.querySelectorAll('ui5-mcb-item');
    if(items) items.forEach((item) => {
      item['selected'] = false;
    });
  }

  changeRadioButton(type): void {
    if(type && type == 'trailer') {
      this.formDialog.patchValue({
        vehicle: this.trailerPlate
      });
    } else {
      this.formDialog.patchValue({
        vehicle: this.vehiclePlate
      });
    }
  }

  async setFilterSel(ev): Promise<void> {
    this.filter = ev.detail.selectedOption.value;
    await this.getMaintenancesSync();
    if(this.filter) {
        this.rows = this.rows.filter((el) => {
          return el.U_Status == this.filter;
        });
    }
    this.popoverFilterEl.nativeElement.close();
  }

  closeDialog() {
    const type = this.typeEl.nativeElement;
    const types = this.typesEl.nativeElement;
    const action = this.actionEl.nativeElement;
    const vehicle = this.vehicleEl.nativeElement;
    type.setAttribute('value-state', '');
    types.setAttribute('value-state', '');
    action.setAttribute('value-state', '');
    action.setAttribute('value-state', '');
    vehicle.setAttribute('value-state', '');
    this.resetComboBox();
    this.formDialog.reset();
    /*for(let i = 0; i < this.files.length; i++) {
      if(this.files[i].artigo.toString().includes("TEMP_")) {
        this.deleteFile(this.files[i].id);
      }
    }*/
    this.dialogEl.nativeElement.close();
  }

  saveDialog() {
    const controls = this.formDialog.controls;
    const type = this.typeEl.nativeElement;
    const types = this.typesEl.nativeElement;
    const action = this.actionEl.nativeElement;
    const vehicle = this.vehicleEl.nativeElement;
    type.setAttribute('value-state', '');
    types.setAttribute('value-state', '');
    action.setAttribute('value-state', '');
    vehicle.setAttribute('value-state', '');
    /** check form */
    if(!this.formId && !controls['type'].value) {
      types.setAttribute('value-state', 'Error')
    }
    if (this.formDialog.invalid) {
      Object.keys(controls).forEach(controlName => {
        controls[controlName].markAsTouched()
        if (controls[controlName].invalid) {
          try {eval(controlName).setAttribute('value-state', 'Error')} catch (e) {}
        }
      });
      return;
    }
    /*const formData = {
      U_FioriUser: localStorage.getItem('userid'),
      DocEntry: this.docEntry,
      NOX_MRQ1Collection: []
    };*/
    const formData = [];
    if(this.formId) {
      for(let i in this.maintenances) {
        if(this.maintenances[i]['DocEntry'] == this.formId) {
          formData.push({
            U_Type: controls['type'].value || null,
            U_Action: controls['action'].value || null,
            U_Vehicle: (controls['vehicle'].value ? controls['vehicle'].value.replace(/-/g, '') : null),
            U_Observations: controls['observations'].value || null,
            U_Status: 'O',
            DocEntry: this.maintenances[i]['DocEntry']
          });
        } else {
          /*formData.push({
            U_Type: this.maintenances[i]['U_Type'] || null,
            U_Action: this.maintenances[i]['U_Action'] || null,
            U_Vehicle: (this.maintenances[i]['U_Vehicle'] ? this.maintenances[i]['U_Vehicle'].replace(/-/g, '') : null),
            U_Observations: this.maintenances[i]['U_Observations'] || null,
            U_Status: this.maintenances[i]['U_Status'],
            DocEntry: this.maintenances[i]['DocEntry']
          });*/
        }
      }
    } else {
      /*for(let i in this.maintenances) {
        formData.push({
          U_Type: this.maintenances[i]['U_Type'] || null,
          U_Action: this.maintenances[i]['U_Action'] || null,
          U_Vehicle: (this.maintenances[i]['U_Vehicle'] ? this.maintenances[i]['U_Vehicle'].replace(/-/g, '') : null),
          U_Observations: this.maintenances[i]['U_Observations'] || null,
          U_Status: 'O',
          DocEntry: this.maintenances[i]['DocEntry']
        });
      }*/

      for (let i = 0; i < this.types.length; i++){
        formData.push({
          U_Type: this.types[i],
          U_Action: controls['action'].value || null,
          U_Vehicle: (controls['vehicle'].value ? controls['vehicle'].value.replace(/-/g, '') : null),
          U_Observations: controls['observations'].value || null,
          U_Status: 'O',
        });
      }

    }
    this.loading.show();
    this.apiService.saveMaintenanceV2(formData).subscribe(data => {
      console.log(data);
      if(data && data['Success'] == true) {
        this.notification.show('Success','Submetido com sucesso');
        this.formDialog.reset();
        this.rerender();
        this.closeDialog();
      } else {
        this.notification.show('Error',data['Msg']);
      }
    },error => {
      this.loading.hide();
      this.notification.show('Error',JSON.stringify(error['error']));
      console.log(error.error);
    },() => {
      this.loading.hide();
    });
  }

  getByValue(arr, property, id, value): string {

    let result = arr.filter(function(o) {
      return o[id] == value;
    });

    return (result && result[0]) ? result[0][property] : ''; // or undefined
  }

  getMaintenances(): void {
    this.loading.show();
    this.apiService.getMaintenancesV2()
      .subscribe(data => {
        // @ts-ignore
        if(data) {
          // @ts-ignore
          this.rows = data;
          // @ts-ignore
          this.maintenances = data;
          //this.docEntry = data['DocEntry'];
          if(this.rows && this.rows.length > 0) {
            this.rows = this.rows.filter((el) => {
              return el.U_Status == 'O';
            });
            this.filteredData = this.rows;
            if(this.rows.length > 0) this.columnsWithSearch = Object.keys(this.rows[0]);
            this.selected = [];
            this.toggleAllRows();
          }
        }
      },error => {
        this.loading.hide();
        this.notification.show('Error',JSON.stringify(error['error']));
        console.log(error.error);
      },() => {
        this.loading.hide();
      });
  }

  async getMaintenancesSync(): Promise<void> {
    this.loading.show();
    try {
      var res = await this.apiService.getMaintenancesSyncV2();
      if(res) {
        // @ts-ignore
        this.rows = res;
        // @ts-ignore
        this.maintenances = res;
        //this.docEntry = res['DocEntry'];
        if(this.rows && this.rows.length > 0) {
          this.filteredData = this.rows;
          this.columnsWithSearch = Object.keys(this.rows[0]);
          this.selected = [];
          this.toggleAllRows();
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.loading.hide();
    }

  }

  getBreakdowns(): void {
    this.apiService.getBreakdownsV2()
      .subscribe(data => {
        // @ts-ignore
        this.breakTypes = data;
        //this.breakTypes.unshift({ Code: '', Name: ''});
      },error => {
        console.log(error.error);
      });
  }

  checkLogin(): void {
    this.loading.show();
    this.apiService.checkLogin(moment().format('YYYY-MM-DD'))
      .subscribe(data => {
        if(!data['U_Status'] || data['U_Status'] == "CHECKOUT") {
          this.router.navigate(['/']);
          this.notification.show('Info','Efectue o Checkin!');
        } else {
          this.vehiclePlate = data['MatriculaVeiculo'];
          setTimeout(() => {
            this.getDriverDetails();
            this.getBreakdowns();
            this.getMaintenances();
          },100);
        }
      },error => {
        this.loading.hide();
        this.notification.show('Error',JSON.stringify(error['error']));
        console.log(error.error);
      },() => {
        this.loading.hide();
      });
  }

  getDriverDetails(): void {
    this.loading.show();
    this.apiService.getDriverDetails()
      .subscribe(data => {
        if(data) {
          this.vehicleCode = data['VehicleCode'];
          this.trailerPlate = data['TrailerPlate'];
        }
      },error => {
        this.loading.hide();
        this.notification.show('Error',JSON.stringify(error['error']));
        console.log(error.error);
      },() => {
        this.loading.hide();
      });
  }

  openFiles(): void {
    this.loading.show();
    this.apiService.getFiles(((this.formId) ? (this.formId)
      : 'TEMP_' + localStorage.getItem('userid')),'SIT_OMRQ')
      .subscribe(data => {
        // @ts-ignore
        this.files = data
        this.dialogFilesEl.nativeElement.show();
      },error => {
        this.loading.hide();
        this.notification.show('Error',JSON.stringify(error['error']));
        console.log(error.error);
      },() => {
        this.loading.hide();
      });
  }

  closeFiles(): void {
    this.dialogFilesEl.nativeElement.close();
  }

  downloadFile(event,url,filename): void {
    //console.log(event);
    let preview = event?.srcElement.classList.contains('preview-link');
    if(event?.isMarked == 'link' && !preview) {
      this.apiService.downloadFile(url,filename);
    }
  }

  previewFile(event,url,filename): void {
    let preview = event?.srcElement.classList.contains('preview-link');
    if(event?.isMarked == 'link' && preview) {
      const cleanURL = this.apiService.getFileURL(url);
      const ext = filename.substring(filename.lastIndexOf('.') + 1);
      this.isPdf = ext == 'pdf';
      this.fileViewer = {
        src: cleanURL,
        name: filename
      }
      this.dialogPreviewEl.nativeElement.show();
    }
  }

  closeDialogPreview(): void {
    this.dialogPreviewEl.nativeElement.close();
  }

  getFiles(): void {
    setTimeout(() => {
      this.loading.show();
      this.apiService.getFiles(((this.formId) ? (this.formId)
        : 'TEMP_' + localStorage.getItem('userid')),'SIT_OMRQ')
        .subscribe(data => {
          // @ts-ignore
          this.files = data;
        },error => {
          this.loading.hide();
          this.notification.show('Error',JSON.stringify(error['error']));
          console.log(error.error);
        },() => {
          this.loading.hide();
        });
    },100);
  }

  uploadFile(file, name, size, type, objcode, objtype): void {
    this.loading.show();
    this.apiService.uploadFile(file, name, size, type, objcode, objtype)
      .subscribe(data => {
        this.getFiles();
      },error => {
        this.loading.hide();
        this.notification.show('Error',JSON.stringify(error['error']));
        console.log(error.error);
      },() => {
        this.loading.hide();
      });
  }

  updateFilesTemp(id, code, type): void {
    this.loading.show();
    this.apiService.updateFilesTemp(id, code, type)
      .subscribe(data => {
        console.log(data);
      },error => {
        this.loading.hide();
        this.notification.show('Error',JSON.stringify(error['error']));
        console.log(error.error);
      },() => {
        this.loading.hide();
      });
  }

  deleteFile(id): void {
    const self = this;
    //self.alertDialog.confirm(self.translate.instant('general.confirmdelete'),function(){
    self.loading.show();
    self.apiService.deleteFile(id)
      .subscribe(data => {
        self.getFiles();
      },error => {
        self.loading.hide();
        self.notification.show('Error',JSON.stringify(error['error']));
        console.log(error.error);
      },() => {
        self.loading.hide();
      });
    //},function(){});
  }

  clearFiles(event): void {
    try {
      if(event.target?.files.length > 0) {
        const input = event.target?.shadowRoot.querySelector('input');
        if(input) {
          input.value = null;
        }
      }
    } catch (e) {
      //console.log(e);
    }
  }

  getWidth() {
    return window.innerWidth;
  }
}
