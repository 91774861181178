<ui5-page id="page" background-design="List">
  <header class="detail-page-header">
    <div class="detail-page-header-bar">
      <ui5-title class="title content-left">{{'maintenance.maintenances' | translate}}</ui5-title>
      <ui5-button
        design="Transparent"
        icon="synchronize"
        class="action-button" (click)="rerender()"></ui5-button>
    </div>
  </header>
  <div class="details-page">
    <div class="toolbar">
      <div class="btns-container">
        <ui5-button #addBtn icon="add" design="Emphasized" (click)="add()" style="margin: 0 8px 0 6px;"></ui5-button>
        <ui5-button #deletetn icon="delete" design="Negative" style="margin: 0 8px 0 0;"
                    (click)="removeSelected()" ></ui5-button>
        <ui5-button #filterBtn icon="overflow" tooltip="Filtros" style="transform: rotate(90deg);"></ui5-button>
      </div>

      <ui5-input #searchInput class="searchInput" (keyup)="updateFilter($event)" placeholder="{{'general.search' | translate}}..." >
        <ui5-icon id="searchIcon" slot="icon" name="search"></ui5-icon>
      </ui5-input>
    </div>

    <ngx-datatable
      #datatable
      style="width: 100%"
      class="material"
      [rows]="rows"
      [columnMode]="ColumnMode.force"
      [headerHeight]="50"
      [footerHeight]="50"
      rowHeight="auto"
      [limit]="10"
      [selected]="selected"
      [selectionType]="SelectionType.checkbox"
      [messages]="{emptyMessage: 'general.emptymessage' | translate,
        totalMessage: 'general.totalmessage' | translate,
        selectedMessage: 'general.selectedmessage' | translate}"
      (activate)="onActivate($event)"
      (select)="onSelect($event)">

      <!-- Row Detail Template -->
      <ngx-datatable-row-detail #myDetailRow (toggle)="onDetailToggle($event)">
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
          <div class="mobile-row" (click)="edit(row)">
            <div class="row-line-container">
              <ui5-label class="row-title">{{'maintenance.request' | translate}}:</ui5-label>
              <span class="row-line">{{getByValue(maintenanceTypes,'value', 'id', row['U_Action'])}}</span>
              <ui5-label class="row-title">{{'maintenance.plate' | translate}}:</ui5-label>
              <span class="row-line">{{ row['U_Vehicle'] }}</span>
              <ui5-label class="row-title">{{'maintenance.observations' | translate}}:</ui5-label>
              <span class="row-line">{{ row['U_Observations'] }}</span>
              <ui5-label class="row-title">{{'maintenance.created' | translate}}:</ui5-label>
              <span class="row-line">{{ row['U_CreateDate'] }}</span>
              <ui5-label class="row-title">{{'maintenance.updated' | translate}}:</ui5-label>
              <span class="row-line">{{ row['U_UpdateDate'] }}</span>
              <ui5-label class="row-title">{{'maintenance.status' | translate}}:</ui5-label>
              <!--<span class="row-line">{{ row['U_Status'] }}</span>-->
              <div style="margin: 5px 0 15px">
                <ui5-badge *ngIf="row['U_Status'] == 'O'" color-scheme="6">
                  {{'maintenance.open' | translate}}
                </ui5-badge>
                <ui5-badge *ngIf="row['U_Status'] == 'R'" color-scheme="2">
                  {{'maintenance.rejected' | translate}}
                </ui5-badge>
                <ui5-badge *ngIf="row['U_Status'] == 'A'" color-scheme="7">
                  {{'maintenance.approved' | translate}}
                </ui5-badge>
                <ui5-badge *ngIf="row['U_Status'] == 'C'" color-scheme="1">
                  {{'maintenance.canceled' | translate}}
                </ui5-badge>
              </div>
            </div>
          </div>
        </ng-template>
      </ngx-datatable-row-detail>

      <ngx-datatable-column
        prop="U_Status"
        class="checkboxTable"
        [width]="(getWidth() < 380) ? 100 : 65"
        [maxWidth]="(getWidth() < 380) ? 100 : 65"
        [sortable]="false"
        [canAutoResize]="false"
        [draggable]="false"
        [resizeable]="false">
        <ng-template
          ngx-datatable-header-template
          let-value="value"
          let-allRowsSelected="allRowsSelected"
          let-selectFn="selectFn">
          <ui5-checkbox #allRowsCheck class="select-all pointer" [checked]="allRowsSelected"
                        (change)="selectFn(!allRowsSelected)"></ui5-checkbox>
        </ng-template>
        <ng-template
          ngx-datatable-cell-template
          let-value="value"
          let-isSelected="isSelected"
          let-onCheckboxChangeFn="onCheckboxChangeFn">
          <ui5-checkbox [attr.disabled]="value != 'O' ? '' : null"
              [checked]="isSelected" (click)="$event.stopPropagation()"
                        (change)="onCheckboxChangeFn($event)"></ui5-checkbox>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column prop="U_Type" [flexGrow]="3">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span class="pointer" (click)="sort()">{{'maintenance.breakdown' | translate}}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <b>{{getByValue(breakTypes,'Name', 'Code', value)}}</b>
          <span class="row-id">{{row['DocEntry']}}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column prop="U_Action" [flexGrow]="1">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span class="pointer mobile-hidden" (click)="sort()">{{'maintenance.request' | translate}}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <span class="mobile-hidden">{{getByValue(maintenanceTypes,'value', 'id', value)}}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column prop="U_Vehicle" [flexGrow]="1">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span class="pointer mobile-hidden" (click)="sort()">{{'maintenance.plate' | translate}}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <span class="mobile-hidden">{{ value }}</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column prop="U_CreateDate" [flexGrow]="1">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span class="pointer mobile-hidden" (click)="sort()">{{'maintenance.created' | translate}}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <span class="mobile-hidden">{{ value }}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column prop="U_UpdateDate" [flexGrow]="1">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span class="pointer mobile-hidden" (click)="sort()">{{'maintenance.updated' | translate}}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <span class="mobile-hidden">{{ value }}</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column prop="U_Observations" [flexGrow]="1">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span class="pointer mobile-hidden" (click)="sort()">{{'maintenance.observations' | translate}}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <span class="mobile-hidden">{{ value }}</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column prop="U_Status" [flexGrow]="1">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span class="pointer mobile-hidden" (click)="sort()">{{'maintenance.status' | translate}}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <div class="mobile-hidden">
            <ui5-badge *ngIf="value == 'O'" color-scheme="6">
              {{'maintenance.open' | translate}}
            </ui5-badge>
            <ui5-badge *ngIf="value == 'R'" color-scheme="2">
              {{'maintenance.rejected' | translate}}
            </ui5-badge>
            <ui5-badge *ngIf="value == 'A'" color-scheme="7">
              {{'maintenance.approved' | translate}}
            </ui5-badge>
            <ui5-badge *ngIf="value == 'C'" color-scheme="1">
              {{'maintenance.canceled' | translate}}
            </ui5-badge>
          </div>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="" [sortable]="false" [maxWidth]="70" >
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <div class="mobile-hidden">
            <ui5-button class="nav-btn" icon="navigation-right-arrow" design="Transparent"
                        aria-labelledby="lblNavigationRightArrow"></ui5-button>
          </div>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>

  <ui5-dialog #dialogMaintenance  class="dialog-maintenance" header-text="Manutenção">
    <div [formGroup]="formDialog" class="dialog-content">
      <div class="flex-column margin">
        <ui5-label class="block" for="type" required>{{'maintenance.breakdown' | translate}}:</ui5-label>
        <ui5-select [hidden]="!formId" #type placeholder="" class="select" class="full-width"
                    [attr.disabled]="formId && status != 'O' ? '' : null"
                    (change)="formDialog.controls['type'].setValue($event['detail']['selectedOption']['value'])"
                    formControlName="type" ngDefaultControl oragami>
          <ui5-option *ngFor="let ct of breakTypes" [value]="ct?.Code"
                      [selected]="ct?.Code == formDialog.controls['type']['value']">{{ct?.Name}}</ui5-option>
        </ui5-select>
        <ui5-multi-combobox [hidden]="formId" #types placeholder="" class="full-width" placeholder=""
                            (selection-change)="setComboValues($event)" >
          <ui5-mcb-item *ngFor="let ct of breakTypes" [value]="ct?.Code" [text]="ct?.Name"></ui5-mcb-item>
        </ui5-multi-combobox>
      </div>
      <div class="flex-column margin">
        <ui5-label class="block" for="action">{{'maintenance.request' | translate}}:</ui5-label>
        <ui5-select #action placeholder="" class="select" class="full-width"
                    [attr.disabled]="formId && status != 'O' ? '' : null"
                    (change)="formDialog.controls['action'].setValue($event['detail']['selectedOption']['value'])"
                    formControlName="action" ngDefaultControl oragami>
          <ui5-option *ngFor="let ct of maintenanceTypes" [value]="ct.id"
                      [selected]="ct.id == formDialog.controls['action']['value']">{{ct.value}}</ui5-option>
        </ui5-select>
      </div>
      <div class="flex-column" [hidden]="!!formId">
        <ui5-radio-button #vehicleType name="vehicleType" text="{{'checkinout.vehicle' | translate}}" checked="" value="vehicle"
                          (change)="changeRadioButton($event['srcElement']['_state']['value'])">
          <ui5-radio-button></ui5-radio-button>
        </ui5-radio-button>

        <ui5-radio-button name="vehicleType" text="{{'checkinout.trailer' | translate}}" value="trailer"
                          (change)="changeRadioButton($event['srcElement']['_state']['value'])">
          <ui5-radio-button></ui5-radio-button>
        </ui5-radio-button>
      </div>
      <div class="flex-column margin" >
        <ui5-label class="block" for="vehicle" required>{{'maintenance.plate' | translate}}:</ui5-label>
        <ui5-input #vehicle placeholder="" class="full-width" [attr.disabled]="formId && status != 'O' ? '' : null"
                   formControlName="vehicle" ngDefaultControl oragami></ui5-input>
      </div>
      <div class="flex-column margin">
        <ui5-label class="block" for="U_Observations">{{'maintenance.observations' | translate}}:</ui5-label>
        <ui5-textarea rows="5" placeholder="" class="full-width" [attr.disabled]="formId && status != 'O' ? '' : null"
                   formControlName="observations" ngDefaultControl oragami></ui5-textarea>
      </div>
      <div class="flex-column margin">
        <ui5-label class="block" for="Description">{{'maintenance.attachphoto' | translate}}:</ui5-label>
        <ui5-button class="full-width" icon="attachment" [disabled]="formId && status != 'O'"
                    (click)="openFiles()">{{files?.length}}</ui5-button>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <div>
        <ui5-button icon="undo" class="margin-btn" design="Transparent" (click)="closeDialog()">{{'general.back' | translate}}</ui5-button>
        <ui5-button design="Emphasized" (click)="saveDialog()" [disabled]="formId && status != 'O'"
                    icon="save">{{'general.save' | translate}}</ui5-button>
      </div>
    </div>
  </ui5-dialog>
  <!-- Files -->
  <ui5-dialog #dialogFiles  headerText="{{'maintenance.attachphoto' | translate}}" >
    <div style="padding: 0 1rem 1rem;">
      <div slot="header" class="header">
        <ui5-title>{{'general.files' | translate}} ({{files?.length}})</ui5-title>
        <div class="spacer"></div>
        <ui5-file-uploader #photoFile id="photoFile" (click)="clearFiles($event)" hide-input>
          <ui5-button icon="add" design="Transparent">Upload</ui5-button>
        </ui5-file-uploader>
      </div>
      <ui5-upload-collection #photoCollection
                             id="photoCollection"
                             mode="Delete"
                             noDataText="{{'general.nofiles' | translate}}"
                             noDataDescription="{{'general.draghere' | translate}}">
        <ui5-upload-collection-item
          *ngFor="let file of files"
          [fileName]="file?.title + ' - Download'"
          [title]="file?.id"
          (click)="downloadFile($event,file?.href,file?.filename)"
          file-name-clickable
          type=""
          upload-state="Complete">
          <!--<ui5-title level="H4">{{file?.title}}</ui5-title>-->
          <img *ngIf="file?.ThumbnailUrl" src="{{file?.ThumbnailUrl}}" slot="thumbnail">
          <ui5-icon *ngIf="!file?.ThumbnailUrl" name="document-text" slot="thumbnail"></ui5-icon>
          {{'general.uploadedby' | translate}}: {{file?.nomeUser}} · {{'general.in' | translate}}:
          {{file?.createddate | date:'yyyy-MM-dd'}}· {{'general.size' | translate}}: {{file?.filesize}} KB
          <br><ui5-link class="preview-link" (click)="previewFile($event,file?.href,file?.filename);">Pre-visualizar</ui5-link>
        </ui5-upload-collection-item>
      </ui5-upload-collection>
    </div>
    <div slot="footer" class="dialog-footer">
      <div>
        <ui5-button icon="undo" class="margin-btn" design="Transparent" (click)="closeFiles()">{{'general.back' | translate}}</ui5-button>
      </div>
    </div>
  </ui5-dialog>

  <ui5-dialog #dialogPreview header-text="{{'tasks.maps' | translate}}" stretch>
    <div class="dialog-content dialog-preview">
      <ngx-extended-pdf-viewer
        *ngIf="isPdf"
        [src]="fileViewer?.src.split(',').pop()"
        backgroundColor="#E5E5E5"
        theme="dark"
        [filenameForDownload]="fileViewer?.filename"
        [useBrowserLocale]="true"
        [handTool]="false"
        [height]="'100%'"
        [showSidebarButton]="false"
        [showHandToolButton]="false"
        [showPrintButton]="true"
        [showDownloadButton]="true"
        [showOpenFileButton] ="false"
        [showBookmarkButton] ="false"
        [showSecondaryToolbarButton] ="true"
        [delayFirstView]="1">
      </ngx-extended-pdf-viewer>
      <div *ngIf="!isPdf" class="preview">
        <img [src]="fileViewer?.src" >
      </div>
    </div>
    <div slot="footer" class="dialog-footer fixed">
      <ui5-button icon="undo" class="margin-btn" design="Transparent" (click)="closeDialogPreview()">
        {{'general.back' | translate}}</ui5-button>
    </div>
  </ui5-dialog>

  <ui5-popover #popoverFilter id="popoverFilter" placement-type="Bottom">
    <!--<div class="popover-header">
      <ui5-title style="padding: 0.25rem 1rem 0rem 1rem"></ui5-title>
    </div>-->
    <div class="popover-content">
      <ui5-select #filterSwitch (change)="setFilterSel($event)" style="--_ui5-input-icons-count:2;">
        <ui5-option value="">{{'maintenance.all' | translate}}</ui5-option>
        <ui5-option value="O" selected>{{'maintenance.open' | translate}}</ui5-option>
        <ui5-option value="A">{{'maintenance.approved' | translate}}</ui5-option>
        <ui5-option value="R">{{'maintenance.rejected' | translate}}</ui5-option>
        <ui5-option value="C">{{'maintenance.canceled' | translate}}</ui5-option>
      </ui5-select>
    </div>
  </ui5-popover>

</ui5-page>
