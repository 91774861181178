<!--<ui5-bar class="ui5-bar" design="Header" style="position: sticky;top:0;z-index:2;">
  <ui5-button icon="home" title="Go home" design="Transparent" slot="startContent"></ui5-button>
  <ui5-label id="basic-label" slot="middleContent">Abastecimento</ui5-label>
  <ui5-button design="Emphasized" slot="endContent" (click)="submit()">{{'general.submit' | translate}}</ui5-button>
</ui5-bar>-->
<ui5-page id="page" class="page" background-design="Solid">
  <header class="detail-page-header">
    <div class="detail-page-header-bar">
      <ui5-title class="title-header content-left">{{ driver?.user }}</ui5-title>
      <span class="sub-title">{{ driver?.VehiclePlate }}</span>
    </div>
  </header>
  <div class="details-page">
    <!--<ui5-title level="H3">Amilcar</ui5-title
    <div class="ui5-content-border"><span></span></div>>-->
      <div [formGroup]="formRefuelling" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px">
        <div fxFlex="auto">
          <div class="ui5-form-border"></div>
          <ui5-title level="H5">{{'refuelling.local' | translate}}</ui5-title>
          <div class="ui5-form-border"></div>
          <div class="flex-column margin">
            <ui5-label class="block" for="U_Bomb" required>{{'refuelling.pump' | translate}}:</ui5-label>
            <ui5-select #pump placeholder=""
                        class="select" class="full-width"
                        (change)="formRefuelling.controls['U_Bomb'].setValue($event['detail']['selectedOption']['value']);
                        stationChange($event['detail']['selectedOption']['value']);"
                        formControlName="U_Bomb" ngDefaultControl required oragami>
              <ui5-option *ngFor="let pp of pumps" [value]="pp.Code"
                          [selected]="pp.Code == formRefuelling.controls['U_Bomb']['value']">{{pp.Name}}</ui5-option>
            </ui5-select>
          </div>
          <div class="flex-column margin">
            <ui5-label class="block" for="BombAddress" >{{'refuelling.address' | translate}}:</ui5-label>
            <ui5-input placeholder="" class="full-width"
                       formControlName="BombAddress" readonly ngDefaultControl origami></ui5-input>
          </div>
          <div class="flex-column margin">
            <ui5-label class="block" for="Suplier">{{'refuelling.supplier' | translate}}:</ui5-label>
            <ui5-input placeholder="" class="full-width"
                       formControlName="Suplier" readonly ngDefaultControl oragami></ui5-input>
          </div>
        </div>

        <div fxFlex="auto">
          <div class="ui5-form-border"></div>
          <ui5-title level="H5">{{'refuelling.details' | translate}}</ui5-title>
          <div class="ui5-form-border"></div>
          <div class="flex-column margin">
            <ui5-label class="block" for="Date" required>{{'checkinout.currentdate' | translate}}:</ui5-label>
            <ui5-date-picker #date class="full-width" formControlName="Date"
                             format-pattern="yyyy-MM-dd" ngDefaultControl origami></ui5-date-picker>
          </div>
          <div class="flex-column margin">
            <ui5-label class="block" for="Hour" required>{{'checkinout.currenthour' | translate}}:</ui5-label>
            <ui5-time-picker #hour class="full-width" formControlName="Hour"
                             format-pattern="HH:mm" ngDefaultControl origami></ui5-time-picker>
          </div>
          <div class="flex-column margin">
            <ui5-label class="block" for="Odometer" required>Kms:</ui5-label>
            <ui5-input #odometer type="Number" placeholder="" class="full-width"
                       formControlName="Odometer" ngDefaultControl origami></ui5-input>
          </div>
          <div class="flex-column margin">
            <ui5-label class="block" for="U_Liters">{{'refuelling.diesel' | translate}}({{'refuelling.liters' | translate}}):</ui5-label>
            <ui5-input #liters type="Number" placeholder="" class="full-width"
                       formControlName="U_Liters" ngDefaultControl oragami></ui5-input>
          </div>
          <div class="flex-column margin">
            <ui5-label class="block" for="U_Liters_2">AdBlue({{'refuelling.liters' | translate}}):</ui5-label>
            <ui5-input #liters2 placeholder="" type="Number" class="full-width"
                       formControlName="U_Liters_2" ngDefaultControl oragami></ui5-input>
          </div>

          <div class="flex-column margin">
            <ui5-label class="block" for="U_Liters_3">{{'refuelling.literspump' | translate}}:</ui5-label>
            <ui5-input placeholder="" type="Number" class="full-width"
                       formControlName="U_Liters_3" ngDefaultControl oragami></ui5-input>
          </div>
        </div>

        <div fxFlex="auto">
          <div class="ui5-form-border"></div>
          <ui5-title level="H5">{{'refuelling.receipts' | translate}}</ui5-title>
          <div class="ui5-form-border"></div>
          <div class="flex-column margin">
            <ui5-label class="block" for="U_NrTalon">{{'refuelling.receiptnumbergas' | translate}}:</ui5-label>
            <ui5-input placeholder="" class="full-width"
                       formControlName="U_NrTalon" ngDefaultControl oragami></ui5-input>
          </div>
          <div class="flex-column margin">
            <ui5-label #filesGasLbl class="block" for="Description">{{'refuelling.attachreceiptgas' | translate}}:</ui5-label>
            <ui5-button class="full-width" icon="attachment" (click)="openFiles('16')">{{fileCountGas}}</ui5-button>
          </div>
      <!--</div>

        <div fxFlex="auto">
          <div class="ui5-form-border"></div>
          <ui5-title level="H5">{{'refuelling.receiptadblue' | translate}}</ui5-title>
          <div class="ui5-form-border"></div>-->
          <div class="flex-column margin">
            <ui5-label class="block" for="U_NrTalonAdblue">{{'refuelling.receiptnumberadblue' | translate}}:</ui5-label>
            <ui5-input placeholder="" class="full-width"
                       formControlName="U_NrTalonAdblue" ngDefaultControl oragami></ui5-input>
          </div>
          <div class="flex-column margin">
            <ui5-label #filesAdBlueLbl class="block" for="Description" >{{'refuelling.attachreceiptadblue' | translate}}:</ui5-label>
            <ui5-button class="full-width" icon="attachment" (click)="openFiles('17')">{{fileCountAdBlue}}</ui5-button>
          </div>
        </div>
    </div>
    <div class="footer">
      <!--<ui5-messagestrip type="Information">{{formRefuelling.value | json}}</ui5-messagestrip>-->
      <!--<ui5-button design="Transparent" class="margin-btn" (click)="goBack()">{{'general.back' | translate}}</ui5-button>
      <ui5-button design="Emphasized" class="margin-btn" (click)="exportSAFT()">{{'pos.export' | translate}}</ui5-button>-->
    </div>
  </div>
  <div slot="footer" class="footer-sticky">
    <ui5-bar design="Footer">
      <ui5-button  icon="mileage" slot="startContent"  class="margin-btn" design="Default" (click)="openHistory()">{{'refuelling.history' | translate}}</ui5-button>
      <ui5-button icon="accept" design="Positive" slot="endContent" (click)="submit()">{{'general.submit' | translate}}</ui5-button>
      <!--<ui5-button design="Transparent" slot="endContent" routerLink="/tasks" >{{'general.back' | translate}}</ui5-button>-->
    </ui5-bar>
  </div>

  <!-- Incidences List -->
  <ui5-dialog #dialogHistory stretch="true" headerText="{{'refuelling.refuellinghistory' | translate}}" >
    <div style="padding: 0 1rem 1rem;">
      <br>
      <ui5-title>{{'refuelling.refuellings' | translate}} ({{history?.length}})</ui5-title>
      <br>
      <ui5-table class="demo-table" id="table">
        <ui5-table-column slot="columns" style="width: 12rem">
          <span style="line-height: 1.4rem">{{'refuelling.refuellings' | translate}}</span>
        </ui5-table-column>
        <ui5-table-column slot="columns" min-width="800" popinText="{{'refuelling.pump' | translate}}" demand-popin>
          <span style="line-height: 1.4rem">{{'refuelling.pump' | translate}}</span>
        </ui5-table-column>
        <ui5-table-column slot="columns" min-width="600" popin-text="Kms" demand-popin>
          <span style="line-height: 1.4rem">Kms</span>
        </ui5-table-column>
        <ui5-table-column slot="columns" min-width="600" popinText="{{'refuelling.fueltype' | translate}}" demand-popin>
          <span style="line-height: 1.4rem">{{'refuelling.fueltype' | translate}}</span>
        </ui5-table-column>
        <ui5-table-column slot="columns" min-width="600" popinText="{{'refuelling.liters' | translate}}" demand-popin>
          <span style="line-height: 1.4rem">{{'refuelling.liters' | translate}}</span>
        </ui5-table-column>
        <ui5-table-column slot="columns" min-width="600" popinText="{{'refuelling.receiptnumber' | translate}}" demand-popin>
          <span style="line-height: 1.4rem">{{'refuelling.receiptnumber' | translate}}</span>
        </ui5-table-column>
        <ui5-table-row *ngFor="let his of history">
          <ui5-table-cell class="align-middle">
            <span class="table-cell-content">{{his.Date}}</span>
          </ui5-table-cell>
          <ui5-table-cell class="align-middle">
            <span class="table-cell-content">{{his.U_NameBomb}}</span>
          </ui5-table-cell>
          <ui5-table-cell class="align-middle">
            <span class="table-cell-content">{{his.U_Kms}}</span>
          </ui5-table-cell>
          <ui5-table-cell class="align-middle">
            <span class="table-cell-content">{{his.Type}}</span>
          </ui5-table-cell>
          <ui5-table-cell class="align-middle">
            <span class="table-cell-content">{{his.U_Liters}}</span>
          </ui5-table-cell>
          <ui5-table-cell class="align-middle">
            <span class="table-cell-content">{{his.U_NrTalon}}</span>
          </ui5-table-cell>
        </ui5-table-row>
      </ui5-table>
    </div>
    <div slot="footer" class="dialog-footer fixed">
      <ui5-button icon="undo" class="margin-btn" design="Transparent" (click)="closeHistory()">{{'general.back' | translate}}</ui5-button>
    </div>
  </ui5-dialog>

  <!-- Files -->
  <ui5-dialog #dialogFiles  headerText="{{'refuelling.attachreceipt' | translate}}" >
    <div style="padding: 0 1rem 1rem;">
      <div slot="header" class="header">
        <ui5-title>{{'general.files' | translate}} ({{files?.length}})</ui5-title>
        <div class="spacer"></div>
        <ui5-file-uploader #ticketFile id="ticketFile" (click)="clearFiles($event)" hide-input>
          <ui5-button icon="add" design="Transparent">Upload</ui5-button>
        </ui5-file-uploader>
      </div>
      <ui5-upload-collection #ticketCollection
         id="ticketCollection"
         mode="Delete"
         noDataText="{{'general.nofiles' | translate}}"
         noDataDescription="{{'general.draghere' | translate}}">
        <ui5-upload-collection-item
          *ngFor="let file of files"
          [fileName]="file?.title + ' - Download'"
          [title]="file?.id"
          (click)="downloadFile($event,file?.href,file?.filename)"
          file-name-clickable
          type=""
          upload-state="Complete">
          <!--<ui5-title level="H4">{{file?.title}}</ui5-title>-->
          <img *ngIf="file?.ThumbnailUrl" src="{{file?.ThumbnailUrl}}" slot="thumbnail">
          <ui5-icon *ngIf="!file?.ThumbnailUrl" name="document-text" slot="thumbnail"></ui5-icon>
          {{'general.uploadedby' | translate}}: {{file?.nomeUser}} · {{'general.in' | translate}}:
          {{file?.createddate | date:'yyyy-MM-dd'}}· {{'general.size' | translate}}: {{file?.filesize}} KB
          <br><ui5-link class="preview-link" (click)="previewFile($event,file?.href,file?.filename);">Pre-visualizar</ui5-link>
        </ui5-upload-collection-item>
      </ui5-upload-collection>
    </div>
    <div slot="footer" class="dialog-footer">
      <div>
        <ui5-button icon="undo" class="margin-btn" design="Transparent" (click)="closeFiles()">{{'general.back' | translate}}</ui5-button>
      </div>
    </div>
  </ui5-dialog>

  <ui5-dialog #dialogPreview header-text="{{'tasks.maps' | translate}}" stretch>
    <div class="dialog-content dialog-preview">
      <ngx-extended-pdf-viewer
        *ngIf="isPdf"
        [src]="fileViewer?.src.split(',').pop()"
        backgroundColor="#E5E5E5"
        theme="dark"
        [filenameForDownload]="fileViewer?.filename"
        [useBrowserLocale]="true"
        [handTool]="false"
        [height]="'100%'"
        [showSidebarButton]="false"
        [showHandToolButton]="false"
        [showPrintButton]="true"
        [showDownloadButton]="true"
        [showOpenFileButton] ="false"
        [showBookmarkButton] ="false"
        [showSecondaryToolbarButton] ="true"
        [delayFirstView]="1">
      </ngx-extended-pdf-viewer>
      <div *ngIf="!isPdf" class="preview">
        <img [src]="fileViewer?.src" >
      </div>
    </div>
    <div slot="footer" class="dialog-footer fixed">
      <ui5-button icon="undo" class="margin-btn" design="Transparent" (click)="closeDialogPreview()">
        {{'general.back' | translate}}</ui5-button>
    </div>
  </ui5-dialog>
</ui5-page>
