<ui5-page id="page" class="page" background-design="Solid">
  <header class="detail-page-header">
    <div class="detail-page-header-bar">
      <ui5-title class="title">
        {{user}}
        <!--<span class="sub-title">test</span>-->
        <br>
        <ui5-badge style="font-size:13px;" *ngIf="!isCheckedin" color-scheme="1">Checked-out</ui5-badge>
        <ui5-badge style="font-size:13px;" *ngIf="isCheckedin" color-scheme="8">Checked-in</ui5-badge>
      </ui5-title>
      <div>
        <ui5-avatar icon="employee" size="S"></ui5-avatar>
      </div>
    </div>
  </header>
  <div class="details-page">
    <ui5-title *ngIf="isCheckedin" level="H3">Check-out</ui5-title>
    <ui5-title *ngIf="!isCheckedin" level="H3">Check-in</ui5-title>
    <div class="ui5-content-border"><span></span></div>
    <ui5-message-strip *ngIf="!isCheckedin" type="Information">{{'checkinout.definevehicle' | translate}}</ui5-message-strip>
    <ui5-message-strip *ngIf="isCheckedin" type="Information">{{'checkinout.verifykms' | translate}}</ui5-message-strip>
      <div [formGroup]="formCheck" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px">
        <div fxFlex="50" >
          <div class="ui5-form-border"></div>
          <ui5-title level="H5">{{'checkinout.infovehicle' | translate}}</ui5-title>
          <div class="ui5-form-border"></div>
          <div class="flex-column margin">
            <ui5-label class="block" for="vehicle" required>{{'checkinout.vehicle' | translate}}:</ui5-label>
            <ui5-input #vehicleInput placeholder="{{'checkinout.vehicle' | translate}}" class="full-width"
                       formControlName="vehicle" ngDefaultControl readonly origami></ui5-input>
            <ui5-button class="margin-btn barcode-btn" icon="bar-code" design="Transparent" (click)="openBarcode('vehicle')"
                        aria-labelledby="lblBarCode" [disabled]="isCheckedin">{{'checkinout.changevehicle' | translate}}</ui5-button>
          </div>
          <div class="flex-column margin">
            <ui5-label class="block" for="trailer">{{'checkinout.trailer' | translate}}:</ui5-label>
            <ui5-input placeholder="{{'checkinout.trailer' | translate}}" class="full-width"
                       formControlName="trailer" ngDefaultControl readonly oragami></ui5-input>
            <!--<ui5-button class="margin-btn barcode-btn" icon="bar-code" design="Transparent" (click)="openBarcode('trailer')"
                        aria-labelledby="lblBarCode" [disabled]="isCheckedin">{{'checkinout.changetrailer' | translate}}</ui5-button>-->
            <ui5-button class="margin-btn barcode-btn" icon="bar-code" design="Transparent" (click)="openBarcode('trailer')"
                        aria-labelledby="lblBarCode" disabled>{{'checkinout.changetrailer' | translate}}</ui5-button>
          </div>

        </div>

        <div fxFlex="50">
          <div class="ui5-form-border"></div>
          <ui5-title level="H5">{{'checkinout.details' | translate}}</ui5-title>
          <div class="ui5-form-border"></div>
          <div class="flex-column margin">
            <ui5-label class="block" for="date" required>{{'checkinout.currentdate' | translate}}:</ui5-label>
            <ui5-input placeholder="{{'checkinout.currentdate' | translate}}" class="full-width"
                       formControlName="date" ngDefaultControl readonly origami></ui5-input>
          </div>
          <div class="flex-column margin">
            <ui5-label class="block" for="hour" required>{{'checkinout.currenthour' | translate}}:</ui5-label>
            <ui5-input placeholder="{{'checkinout.currenthour' | translate}}" class="full-width"
                       formControlName="hour" ngDefaultControl readonly oragami></ui5-input>
          </div>
          <div class="flex-column margin">
            <ui5-label class="block" for="kms">Kms:</ui5-label>
            <ui5-input type="Number" placeholder="Kms" class="full-width"
                       formControlName="kms" ngDefaultControl oragami></ui5-input>
          </div>
        </div>


    </div>
    <div class="footer">
      <!--<ui5-messagestrip type="Information">{{formSupplier.value | json}}</ui5-messagestrip>-->
      <!--<ui5-button design="Transparent" class="margin-btn" (click)="goBack()">{{'general.back' | translate}}</ui5-button>
      <ui5-button design="Emphasized" class="margin-btn" (click)="exportSAFT()">{{'pos.export' | translate}}</ui5-button>-->
    </div>
  </div>

  <div slot="footer" class="footer-sticky">
    <ui5-bar design="Footer">
      <ui5-button *ngIf="!isCheckedin" design="Positive" slot="endContent" (click)="submitCheckin()">{{'checkinout.makecheckin' | translate}}</ui5-button>
      <ui5-button *ngIf="isCheckedin" design="Negative" slot="endContent" (click)="submitCheckout()">{{'checkinout.makecheckout' | translate}}</ui5-button>
    </ui5-bar>
  </div>

  <ui5-dialog #dialogBarcode headerText="{{'checkinout.insertplate' | translate}}">
    <div class="dialog-content">
      <div class="flex-column margin">
        <ui5-label class="block" for="barcode"></ui5-label>
        <ui5-input #barcodeInput placeholder="{{'checkinout.insertplate' | translate}}" class="full-width" [(ngModel)]="barcode"
                    ngDefaultControl oragami></ui5-input>
        <ui5-label *ngIf="noScanner" style="margin-top:5px;"
                   class="block">{{'checkinout.scannerunavaible' | translate}}</ui5-label>
        <ui5-button *ngIf="scanner" class="margin-btn" icon="bar-code" design="Transparent" (click)="readBarcode()"
                    aria-labelledby="lblBarCode" style="margin: 5px 0 -20px 0;">Scanner</ui5-button>
        <ui5-button *ngIf="!scanner" class="margin-btn" icon="bar-code" design="Transparent" (click)="openBarCodeAlt('vehicle')"
                    aria-labelledby="lblBarCode" style="margin: 5px 0 -20px 0;">Scanner</ui5-button>
      </div>

    </div>
    <div class="dialog-footer">
      <ui5-button class="margin-btn" design="Emphasized" (click)="confirmBarcode()">OK</ui5-button>
      <ui5-button icon="undo" class="margin-btn" design="Transparent" (click)="closeBarcode()">{{'general.back' | translate}}</ui5-button>
    </div>
  </ui5-dialog>

  <ui5-barcode-scanner-dialog #dlgScanBarcode ></ui5-barcode-scanner-dialog>

</ui5-page>
