<div class="bg-pattern">
  <div class="main-wrapper" fxLayout="row" fxLayoutAlign="center center">
    <div class="signin-content">
      <div class="title-container">
        <img slot="logo" src="/assets/images/sap-logo-svg.svg"/>
        <ui5-title level="H3" class="title">{{'login.welcome' | translate}}</ui5-title>
      </div>
      <div [formGroup]="loginForm" (keyup.enter)="confirmLogin()">
        <div class="flex-column margin">
          <ui5-label class="block" for="userName" required>{{'login.username' | translate}}</ui5-label>
          <ui5-input #username id="username" formControlName="username"
                     placeholder="User" origami></ui5-input>
        </div>
        <div class="flex-column margin">
          <ui5-label class="block" for="password" required>{{'login.password' | translate}}</ui5-label>
          <ui5-input #password id="password" formControlName="password"
                     type="Password" placeholder="password" origami></ui5-input>
        </div>
        <div class="flex-column margin">
          <div>
            <ui5-checkbox style="margin-left: -10px;" formControlName="remember"
                          (change)="loginForm.controls['remember'].setValue($event['target']['checked'])"
                          [checked]="loginForm.controls['remember']['value']"
                          ngDefaultControl text="{{'login.rememberme' | translate}}" oragami></ui5-checkbox>
            <ui5-button class="barcode-login-btn" icon="bar-code" design="Transparent" (click)="openBarcode('login')"
                        aria-labelledby="lblBarCode" ></ui5-button>
            <!--<ui5-button class="fingerprint-login-btn" icon="" design="Transparent" (click)="fingerprintSignin()"
                        aria-labelledby="lblBarCode" ></ui5-button>-->
          </div>
        </div>
        <ui5-button design="Emphasized" (click)="onSubmit(false)">{{'login.login' | translate}}</ui5-button>

        <div class="footer-logo">
          <span class="sharpthinkit" >Developed by
            <a class="link">
              <span class="sharp">Sharp</span>
              <span class="think">think</span><span class="sharp"><strong>IT</strong></span>
            </a>
          </span>
          <p class="version">v{{version}}</p>
        </div>
      </div>
    </div>
  </div>
  <ui5-dialog #dialogLogin id="dialogLogin" header-text="Login">
    <div class="dialog-content">
      <span>Existe um Login efectuado noutro dispositivo, deseja continuar?</span>
    </div>
    <div class="dialog-footer">
      <ui5-button class="margin-btn" design="Transparent" (click)="closeDialog()">{{'general.cancel' | translate}}</ui5-button>
      <ui5-button design="Emphasized" (click)="confirmLogin()">OK</ui5-button>
    </div>
  </ui5-dialog>

  <ui5-dialog #dialogBarcodeLogin headerText="{{'checkinout.insertbarcode' | translate}}">
    <div class="dialog-content">
      <div class="flex-column margin">
        <ui5-label class="block" for="barcode"></ui5-label>
        <ui5-input #barcodeLoginInput placeholder="{{'checkinout.insertbarcode' | translate}}" class="full-width" [(ngModel)]="barcode"
                   ngDefaultControl oragami></ui5-input>
        <ui5-label *ngIf="noScanner" style="margin-top:5px;"
                   class="block">{{'checkinout.scannerunavaible' | translate}}</ui5-label>
        <ui5-button *ngIf="scanner" class="margin-btn" icon="bar-code" design="Transparent" (click)="readBarcode()"
                    aria-labelledby="lblBarCode" style="margin: 5px 0 -20px 0;">Scanner</ui5-button>
        <ui5-button *ngIf="!scanner" class="margin-btn" icon="bar-code" design="Transparent" (click)="openBarCodeAlt('login')"
                    aria-labelledby="lblBarCode" style="margin: 5px 0 -20px 0;">Scanner</ui5-button>
      </div>
    </div>
    <div class="dialog-footer">
      <ui5-button class="margin-btn" design="Emphasized" (click)="confirmBarcode()">OK</ui5-button>
      <ui5-button icon="undo" class="margin-btn" design="Transparent" (click)="closeBarcode()">{{'general.back' | translate}}</ui5-button>
    </div>
  </ui5-dialog>

  <ui5-barcode-scanner-dialog #dlgScanLogin ></ui5-barcode-scanner-dialog>
</div>
