import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from './message.service';

@Injectable({ providedIn: 'root' })
export class ApiService {

  constructor(private messageService: MessageService,
              private httpClient: HttpClient,
              private translate: TranslateService) { }

  _GetRequestBody() {
    const obj: any = {};
    obj.database = environment.database;
    obj.userid = localStorage.getItem('userid');
    obj.fiori_token = localStorage.getItem(environment.authTokenKey);
    obj.lang = this.translate.currentLang;
    obj.Armazem = '01';
    obj.Filial = '1';
    return obj;
  }

  getDashDriverDetails(date) {
    const body = this._GetRequestBody();
    body.Dados = {data: date, userCode: localStorage.getItem('userid')};
    return this.httpClient.post(environment.apiUrl + '/' + environment.servicePath +
      '/Drivers/Dashboard.xsjs?cmd=Get_DriverDetails',
      body
    );
  }

  getPendingTasks() {
    const body = this._GetRequestBody();
    return this.httpClient.post(environment.apiUrl + '/' + environment.servicePath +
      '/Drivers/Dashboard.xsjs?cmd=Get_Tasks',
      body
    );
  }

  checkLogin(date) {
    const body = this._GetRequestBody();
    body.Dados = {data: date, userCode: localStorage.getItem('userid')};
    return this.httpClient.post(environment.apiUrl + '/' + environment.servicePath +
      '/Drivers/DriversTasks.xsjs?cmd=Check_LogIn',
      body
    );
  }

  getTrailers() {
    const body = this._GetRequestBody();
    return this.httpClient.post(environment.apiUrl + '/' + environment.servicePath +
      '/Drivers/DriversTasks.xsjs?cmd=Get_ListTraillers',
      body
    );
  }

  saveChangeTrailer(data) {
    const body = this._GetRequestBody();
    body.Obj = data;
    return this.httpClient.post(environment.apiUrl + '/' + environment.servicePath +
      '/Drivers/DriversTasks.xsjs?cmd=Save_ChangeTrailler',
      body
    );
  }

  updateProcess(params) {
    const body = this._GetRequestBody();
    body.Dados = params;
    return this.httpClient.post(environment.apiUrl + '/' + environment.servicePath +
      '/Drivers/DriversTasks.xsjs?cmd=updateProcess',
      body
    );
  }

  getProcesses() {
    const body = this._GetRequestBody();
    return this.httpClient.post(environment.apiUrl + '/' + environment.servicePath +
      '/Drivers/DriversTasks.xsjs?cmd=Get_ListProcess',
      body
    );
  }

  getTasks(_body) {
    const body = this._GetRequestBody();
    const __body = { ...body, ..._body };
    return this.httpClient.post(environment.apiUrl + '/' + environment.servicePath +
      '/Drivers/DriversTasks.xsjs?cmd=Get_MasterData',
      __body
    );
  }

  getTask(ids, lineId, product, type, expadd = 1, loadClient = '') {
    const body = this._GetRequestBody();
    body.DocEntry = ids;
    body.LineId = lineId;
    body.Product = product;
    body.Type = type;
    body.ExpAdd = expadd;
    body.LoadClient = loadClient;
    return this.httpClient.post(environment.apiUrl + '/' + environment.servicePath +
      '/Drivers/DriversTasks.xsjs?cmd=Get_Details',
      body
    );
  }

  getTaskDetails(ids,type) {
    const body = this._GetRequestBody();
    body.DocEntry = ids;
    body.Type = type;
    return this.httpClient.post(environment.apiUrl + '/' + environment.servicePath +
      '/Drivers/DriversTasks.xsjs?cmd=Get_DetailExpDest',
      body
    );
  }

  saveLoadOrUnload(details, data) {
    const body = this._GetRequestBody();
    body.dadosEvento = data;
    body.CarPlate = details.CarPlate;
    body.Count = details.Count;
    body.DriverName = details.DriverName;
    body.CodReceived = details.CodReceived;
    return this.httpClient.post(environment.apiUrl + '/' + environment.servicePath +
      '/Drivers/DriversTasks.xsjs?cmd=Save_LoadOrUnload',
      body
    );
  }

  getIncidencesTypes(type) {
    const body = this._GetRequestBody();
    body.Type = type;
    return this.httpClient.post(environment.apiUrl + '/' + environment.servicePath +
      '/Drivers/DriversTasks.xsjs?cmd=Get_IncidencesList',
      body
    );
  }

  saveIncidence(details, incidence, allData) {
    const body = this._GetRequestBody();
    body.CarPlate = details.CarPlate;
    body.DriverName = details.DriverName;
    body.toSave = incidence;
    body.AllData = allData;
    return this.httpClient.post(environment.apiUrl + '/' + environment.servicePath +
      '/Drivers/DriversTasks.xsjs?cmd=Create_Incidence',
      body
    );
  }

  getIncidences(id) {
    const body = this._GetRequestBody();
    body.DocEntry = id;
    return this.httpClient.post(environment.apiUrl + '/' + environment.servicePath +
      '/Drivers/DriversTasks.xsjs?cmd=Get_Incidences',
      body
    );
  }

  sendEmail(data) {
    const body = this._GetRequestBody();
    body.subject = data.subject;
    body.bodyemail = data.bodyemail;
    body.to = data.to;
    body.cc = data.bodyemail;
    body.bcc = data.bodyemail;
    return this.httpClient.post(environment.apiUrl + '/' + environment.servicePath +
      '/Drivers/DriversTasks.xsjs?cmd=sendEmail',
      body
    );
  }

  getMaintenances() {
    const body = this._GetRequestBody();
    return this.httpClient.post(environment.apiUrl + '/' + environment.servicePath +
      '/Drivers/MaintenanceRequest.xsjs?cmd=Get_OpenRequests',
      body
    );
  }

  getBreakdowns() {
    const body = this._GetRequestBody();
    return this.httpClient.post(environment.apiUrl + '/' + environment.servicePath +
      '/Drivers/MaintenanceRequest.xsjs?cmd=Get_ValidValues',
      body
    );
  }

  saveMaintenance(data) {
    const body = this._GetRequestBody();
    body.document = data;
    console.log(body);
    return this.httpClient.post(environment.apiUrl + '/' + environment.servicePath +
      '/Drivers/MaintenanceRequest.xsjs?cmd=CreateOrUpdate_Request',
      body
    );
  }

  deleteMaintenance(id, mantain) {
    const body = this._GetRequestBody();
    body.docentry = id;
    body.delete_all = mantain && mantain.length == 0;
    body.to_mantain = mantain;
    return this.httpClient.post(environment.apiUrl + '/' + environment.servicePath +
      '/Drivers/MaintenanceRequest.xsjs?cmd=Delete_Request',
      body
    );
  }

  getMaintenancesV2() {
    const body = this._GetRequestBody();
    return this.httpClient.post(environment.apiUrl + '/' + environment.servicePath +
      '/Drivers/MaintenanceRequestV2.xsjs?cmd=Get_OpenRequests',
      body
    );
  }

  async getMaintenancesSyncV2() {
    const body = this._GetRequestBody();
    return await this.httpClient.post(environment.apiUrl + '/' + environment.servicePath +
      '/Drivers/MaintenanceRequestV2.xsjs?cmd=Get_OpenRequests',
      body
    ).toPromise();
  }

  getBreakdownsV2() {
    const body = this._GetRequestBody();
    return this.httpClient.post(environment.apiUrl + '/' + environment.servicePath +
      '/Drivers/MaintenanceRequestV2.xsjs?cmd=Get_ValidValues',
      body
    );
  }

  saveMaintenanceV2(data) {
    const body = this._GetRequestBody();
    body.document = data;
    console.log(body);
    return this.httpClient.post(environment.apiUrl + '/' + environment.servicePath +
      '/Drivers/MaintenanceRequestV2.xsjs?cmd=CreateOrUpdate_Request',
      body
    );
  }

  deleteMaintenanceV2(id, deleteArr) {
    const body = this._GetRequestBody();
    body.docentry = id;
    //body.delete_all = deleteArr && deleteArr.length == 0;
    body.to_delete = deleteArr;
    return this.httpClient.post(environment.apiUrl + '/' + environment.servicePath +
      '/Drivers/MaintenanceRequestV2.xsjs?cmd=Delete_Request',
      body
    );
  }

  getGlobalReports() {
    const body = this._GetRequestBody();
    return this.httpClient.post(environment.apiUrl + '/' + environment.servicePath +
      '/Base_Services/GenericMethods.xsjs?cmd=getReportsConfigs',
      body
    );
  }

  getSysConfigs() {
    const body = this._GetRequestBody();
    return this.httpClient.post(environment.apiUrl + '/' + environment.servicePath +
      '/Base_Services/GenericMethods.xsjs?cmd=Get_SysConfigs',
      body
    );
  }

  async getSysConfigsSync() {
    const body = this._GetRequestBody();
    return await this.httpClient.post(environment.apiUrl + '/' + environment.servicePath +
      '/Base_Services/GenericMethods.xsjs?cmd=Get_SysConfigs',
      body
    ).toPromise();
  }

  getMaps(id, params) {
    const body = this._GetRequestBody();
    body.doccode = id;
    body.params = params;
    return this.httpClient.post(environment.apiUrl + '/' + environment.servicePath +
      '/SitFramework/Reporting/reporting.xsjs?cmd=openReportPdf',
      body
    );
  }

  getCheckin(params) {
    const body = this._GetRequestBody();
    body.Dados = params;
    return this.httpClient.post(environment.apiUrl + '/' + environment.servicePath +
      '/Drivers/DriversCheckIn.xsjs?cmd=Check_LogIn',
      body
    );
  }

  getCheckinDetails(params) {
    const body = this._GetRequestBody();
    body.Dados = params;
    return this.httpClient.post(environment.apiUrl + '/' + environment.servicePath +
      '/Drivers/DriversCheckIn.xsjs?cmd=Get_Details',
      body
    );
  }

  saveCheckin(params) {
    const body = this._GetRequestBody();
    body.Obj = params;
    return this.httpClient.post(environment.apiUrl + '/' + environment.servicePath +
      '/Drivers/DriversCheckIn.xsjs?cmd=CheckInSave',
      body
    );
  }

  saveCheckout(params) {
    const body = this._GetRequestBody();
    body.Obj = params;
    return this.httpClient.post(environment.apiUrl + '/' + environment.servicePath +
      '/Drivers/DriverCheckOut.xsjs?cmd=CheckOutSave',
      body
    );
  }

  getDriverDetails() {
    const body = this._GetRequestBody();
    return this.httpClient.post(environment.apiUrl + '/' + environment.servicePath +
      '/Drivers/DriverCheckOut.xsjs?cmd=getDriverDetails',
      body
    );
  }

  getStations() {
    const body = this._GetRequestBody();
    body.userCode = body.userid;
    return this.httpClient.post(environment.apiUrl + '/' + environment.servicePath +
      '/Drivers/DriversRefuelling.xsjs?cmd=Get_Bombs',
      body
    );
  }

  getDriver() {
    const body = this._GetRequestBody();
    body.userCode = body.userid;
    return this.httpClient.post(environment.apiUrl + '/' + environment.servicePath +
      '/Drivers/DriversRefuelling.xsjs?cmd=Get_Details',
      body
    );
  }

  getRefuelHistory(id) {
    const body = this._GetRequestBody();
    body.carPlate = id;
    return this.httpClient.post(environment.apiUrl + '/' + environment.servicePath +
      '/Drivers/DriversRefuelling.xsjs?cmd=Get_HistoryFuel',
      body
    );
  }

  saveFuel(params, fuels, files) {
    const body = this._GetRequestBody();
    body.ListComb = fuels;
    body.Obj = params;
    body.Anexos = [];
    try {
      for(let i = 0; i < files.length; i++) {
        body.Anexos.push({artigo: files[i].artigo});
      }
    } catch (e) {}
    return this.httpClient.post(environment.apiUrl + '/' + environment.servicePath +
      '/Drivers/DriversRefuelling.xsjs?cmd=save_Fuel',
      body
    );
  }

  getCards() {
    const body = this._GetRequestBody();
    return this.httpClient.post(environment.apiUrl + '/' + environment.servicePath +
      '/Drivers/Cards.xsjs?cmd=Get_Details',
      body
    );
  }

  getFiles(code, type, info = '',category = null) {
    const body = this._GetRequestBody();
    body.ObjCode = code;
    body.ObjType = type;
    body.AdditionalInfo = info;
    if(category) {
      body.category = category;
    }
    return this.httpClient.post(environment.apiUrl + '/' + environment.servicePath +
      '/SitFramework/Files.xsjs?cmd=getFiles',
      body
    );
  }

  uploadFile(file, name, size, type, objcode, objtype, category = '', info = '') {

    const body = this._GetRequestBody();
    const headers: any = {};
    headers.Database = body.database;
    headers.userid = body.userid;
    headers.fiori_token = body.fiori_token;
    headers.lang = body.lang;
    headers.nameuser = encodeURI(localStorage.getItem('user'));
    headers.file_name = name;
    headers.file_type = type;
    headers.category = category;
    headers.size = size.toString();
    headers.ObjType = objtype;
    headers.ObjCode = objcode.toString();
    headers.AdditionalInfo = info;

    headers['Content-Type'] = headers.file_type+'; charset=utf-8"';
    //let formData = new FormData();
    //formData.append('file', file);
    return this.httpClient.post(environment.apiUrl + '/' + environment.servicePath +
      '/SitFramework/Files.xsjs?cmd=uploadFile',
      file, { headers: headers }
    );
  }

  updateFilesTemp(id, code, type) {
    const body = this._GetRequestBody();
    body.IdTemp = id;
    body.ObjCode = code;
    body.ObjType = type;
    return this.httpClient.post(environment.apiUrl + '/' + environment.servicePath +
      '/SitFramework/Files.xsjs?cmd=updateFilesTemp',
      body
    );
  }

  deleteFile(id) {
    const body = this._GetRequestBody();
    body.idFile = id;
    return this.httpClient.post(environment.apiUrl + '/' + environment.servicePath +
      '/SitFramework/Files.xsjs?cmd=deleteFile',
      body
    );
  }

  downloadFile(url,filename) {
    const cleanUrl = url.replace('../../SERVICES','');
    const downloadLink = document.createElement("a");
    downloadLink.href = environment.apiUrl + '/' + environment.servicePath + cleanUrl;
    downloadLink.download = filename;
    downloadLink.click();
    downloadLink.remove();
    /*window.open(environment.apiUrl + '/' + environment.servicePath +
      cleanUrl, '_blank');*/
  }

  getFileURL(url) {
    const cleanUrl = url.replace('../../SERVICES','');
    return environment.apiUrl + '/' + environment.servicePath + cleanUrl;
  }
}
