import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { of, Subject} from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { CryptoJS } from '../../services/cryptojs.service';

import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../auth/auth.service';
import { LoadingComponent } from '../../shared/dialogs/loading/loading.component';

import { NotificationComponent } from '../../shared/dialogs/notification/notification.component';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild('dialogLogin') dialogLoginEl: ElementRef;
  @ViewChild('dialogBarcodeLogin') dialogBarcodeLoginEl: ElementRef;
  @ViewChild('dlgScanLogin') dlgScanLoginEl: ElementRef;
  @ViewChild('barcodeLoginInput') barcodeLoginEl: ElementRef;
  @ViewChild('username') usernameEl: ElementRef;
  @ViewChild('password') passwordEl: ElementRef;
  private unsubscribe: Subject<any>;
  private formSubmitAttempt: boolean;
  loginForm: FormGroup;
  returnUrl: string;
  isScanner: boolean;
  scanner: boolean;
  noScanner: boolean;
  type: string;
  version: string;
  barcode = '';
  isScanning;

  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private translate: TranslateService,
              private CryptoJS: CryptoJS,
              private authService: AuthService,
              private loading: LoadingComponent,
              private notification: NotificationComponent) {
    this.unsubscribe = new Subject();
    this.version = environment.appVersion;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.returnUrl = params['returnUrl'] || '/';
    });
    let remember = false;
    let username = '';
    let password = '';
    const rem = localStorage.getItem('remember');
    if(rem && rem == 'Y') {
      username = localStorage.getItem('username');
      password = this.authService.decrypt(localStorage.getItem('token'));
      remember = true;
    }
    this.loginForm = this.fb.group({
      username: [username, Validators.required],
      password: [password, Validators.required],
      remember: remember
    });
    this.authService.setLogout();
    localStorage.setItem('login', 'N');

    // Wait 4 device ready to show Scanner Button
    document.addEventListener('deviceready', this.onDeviceReady.bind(this), false);

    //BarcodeReader Browser Alternative(Requires HTTPS)
    this.readBarCodeAltInit();
  }

  onDeviceReady() {
    if(window['cordova'] && window['cordova']['plugins'] && window['cordova']['plugins']['barcodeScanner']) {
      this.scanner = true;
    }
  }

  confirmLogin(): void {
    this.dialogLoginEl.nativeElement.close();
    this.isScanner = false;
    this.onSubmit(true);
  }

  closeDialog(): void {
    this.dialogLoginEl.nativeElement.close();
  }

  onSubmit(confirm): void {
    const self = this;
    const controls = self.loginForm.controls;
    const username = self.usernameEl.nativeElement;
    const passwd = self.passwordEl.nativeElement;
    let oldUser = localStorage.getItem('username');
    username.setAttribute('value-state', '');
    passwd.setAttribute('value-state', '');
    localStorage.setItem('remember', 'N');
    /** check form */
    if (self.loginForm.invalid) {
      Object.keys(controls).forEach(controlName => {
        controls[controlName].markAsTouched()
        if (controls[controlName].invalid) {
          try {eval(controlName).setAttribute('value-state', 'Error')} catch (e) {}
        }
      });
      return;
    }
    const formData = {
      username: controls['username'].value,
      password: controls['password'].value,
      remember: controls['remember'].value,
    }
    if(formData.remember) {
      localStorage.setItem('remember', 'Y');
      localStorage.setItem('username',  formData.username);
      localStorage.setItem('token', self.authService.encrypt(formData.password));
    }
    self.loading.show();

    /** Fake Login **/
    /*setTimeout(() => {
      self.authService.fakeLogin({userName: formData.username, password: formData.password});
      self.loginForm.reset();
      self.loading.hide();
    }, 1000);*/
    /**  **/

    self.authService.login({userName: formData.username, password: formData.password}, confirm).pipe(
      tap(user => {
        if (user['Success'] == true) {
          self.authService.setLogin();
          localStorage.setItem('login', 'Y');
          localStorage.setItem('userid', user['Login']['User']);
          localStorage.setItem('username', user['Login']['UserName']);
          localStorage.setItem('user', user['Login']['DisplayName']);
          localStorage.setItem(environment.authTokenKey, user['Login']['Token']);
          if(self.isScanner) {
            localStorage.setItem('remember', 'Y');
            localStorage.setItem('token', self.authService.encrypt(formData.password));
          }
          self.loginForm.reset();
          if(this.returnUrl != '/' && formData.username != oldUser) {
            self.router.navigate(['/']);
          } else {
            self.router.navigate([this.returnUrl]);
          }

        } else {
          if(user['MessageCode'] == 'LOGIN_NOTMATCH') {
            self.dialogLoginEl.nativeElement.show();
          } else {
            localStorage.setItem('login', 'N');
            self.loginForm.reset();
            self.notification.show('Error','Login inválido');
          }
        }
      }),
      catchError((err) => {
        self.notification.show('Error',err['Message']);
        return of();
      }),
      takeUntil(self.unsubscribe),
      finalize(() => {
        self.formSubmitAttempt = true;
        self.loading.hide();
      })
    ).subscribe();

  }

  closeBarcode(): void {
    //this.scanner = false;
    this.barcode = '';
    const barcode = this.barcodeLoginEl.nativeElement;
    barcode.setAttribute('value-state', '');
    this.dialogBarcodeLoginEl.nativeElement.close();
  }

  confirmBarcode(): void {
    const barcodeLogin = this.barcodeLoginEl.nativeElement;
    barcodeLogin.setAttribute('value-state', '');
    if(!this.barcode) {
      barcodeLogin.setAttribute('value-state', 'Error');
      return;
    }

    let decBarcode = this.CryptoJS.get('@KEYTO#DECR#NOX#2019#',this.barcode);
    decBarcode = decBarcode.split("|");
    if(decBarcode[0]){
      this.loginForm.patchValue({username: decBarcode[0]})
    }
    if(decBarcode[1]){
      this.loginForm.patchValue({password: decBarcode[1]})
    }

    this.isScanner = true;
    this.onSubmit(false);
  }

  openBarcode(type): void {
    this.type = type;
    this.dialogBarcodeLoginEl.nativeElement.show();
  }

  openBarCodeAlt(type): void {
    this.type = type;
    this.dlgScanLoginEl.nativeElement.show();
  }

  readBarcode(): void {
    const self = this;
    self.isScanning = true;
    window.history.pushState("BackLock", null, "");
    window.onpopstate = (evt) => {
      if(this.isScanning) {
        window.history.pushState("BackLock", null, "");
      }
      return;
    }
    window['cordova']['plugins']['barcodeScanner'].scan(
      function (result) {
        self.isScanning = false;
        if(result.cancelled) {
          self.dialogBarcodeLoginEl.nativeElement.show();
        } else {
          self.barcode = result.text;
          //setTimeout(() => document.body.click(),100);
          setTimeout(function() {
            document.body.click();

            let decBarcode= self.CryptoJS.get('@KEYTO#DECR#NOX#2019#',self.barcode);
            //console.log(this.CryptoJS.set('@KEYTO#DECR#NOX#2019#','test'));
            decBarcode = decBarcode.split("|");
            if(decBarcode[0]){
              self.loginForm.patchValue({username: decBarcode[0]})
            }
            if(decBarcode[1]){
              self.loginForm.patchValue({password: decBarcode[1]})
            }
            self.isScanner = true;
            self.onSubmit(false);
          },100);
        }
      },
      function (error) {
        self.isScanning = false;
        self.notification.show('Error',"Scanning failed: " + error);
      },
      {
        preferFrontCamera : false, // iOS and Android
        showFlipCameraButton : false, // iOS and Android
        showTorchButton : false, // iOS and Android
        torchOn: false, // Android, launch with the torch switched on (if available)
        saveHistory: false, // Android, save scan history (default false)
        prompt : "Place a barcode inside the scan area", // Android
        resultDisplayDuration: 500, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
        //formats : "QR_CODE,PDF_417", // default: all but PDF_417 and RSS_EXPANDED
        orientation : "portrait", // Android only (portrait|landscape), default unset so it rotates with the device
        disableAnimations : true, // iOS
        disableSuccessBeep: false // iOS and Android
      }
    );
  }

  readBarCodeAltInit(): void {
    const self = this;
    setTimeout(function() {
      const dlgScan = self.dlgScanLoginEl.nativeElement;
      dlgScan.addEventListener("scan-success", function(event) {
        dlgScan.close();
        self.noScanner = false;
        self.barcode = event.detail.text;
        let decBarcode = self.CryptoJS.get('@KEYTO#DECR#NOX#2019#',self.barcode);
        decBarcode = decBarcode.split("|");
        if(decBarcode[0]){
          self.loginForm.patchValue({username: decBarcode[0]})
        }
        if(decBarcode[1]){
          self.loginForm.patchValue({password: decBarcode[1]})
        }
        self.isScanner = true;
        self.onSubmit(false);
      });
      dlgScan.addEventListener("scan-error", function(event) {
        self.noScanner = true;
      });
    }, 2000);
  }

  fingerprintSignin() {
    /*const prevUser = this.serverMockService.getUser(this.email);
    if (prevUser) {
      this.webAuthSignin();
    } else {
      this.signup();
    }*/
  }

}
