import {Component, ElementRef, OnInit, AfterViewInit, ViewChild} from '@angular/core';

import {LoadingComponent} from '../../shared/dialogs/loading/loading.component';
import {NotificationComponent} from '../../shared/dialogs/notification/notification.component';
import {AlertService} from '../../services/alert.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {ApiService} from '../../services/api.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-forms',
  templateUrl: './checkinout.component.html',
  styleUrls: [ './checkinout.component.scss' ],
})
export class CheckinoutComponent implements OnInit {
  @ViewChild('vehicleInput') vehicleEl: ElementRef;
  @ViewChild('barcodeInput') barcodeEl: ElementRef;
  @ViewChild('dialogBarcode') dialogBarcodeEl: ElementRef;
  @ViewChild('dlgScanBarcode') dlgScanBarcodeEl: ElementRef;

  formCheck: FormGroup;
  user: string;
  scanner: boolean;
  noScanner: boolean;
  type: string;
  barcode = '';
  checkIn: any;
  driver: any;
  isCheckedin: boolean;
  countries = [
    { id: '', value: '' },
    { id: 1, value: 'Portugal' },
    { id: 2, value: 'Spain' },
    { id: 3, value: 'France' }
  ];
  isScanning;

  constructor(private apiService: ApiService,
              private fb: FormBuilder,
              private router: Router,
              private loading: LoadingComponent,
              private notification: NotificationComponent,
              private alertDialog: AlertService,
              private translate: TranslateService) { }

  ngOnInit(): void {
    this.user = localStorage.getItem('user');
    this.formCheck = this.fb.group({
      vehicle: ['', Validators.required],
      trailer: [''],
      date: [moment().format('YYYY-MM-DD')],
      hour: [moment().format("HH:mm")],
      kms: ['']
    });
    this.getCheckin(true);
    // Wait 4 device ready to show Scanner Button
    document.addEventListener('deviceready', this.onDeviceReady.bind(this), false);

    //BarcodeReader Browser Alternative(Requires HTTPS)
    this.readBarCodeAltInit();
  }

  onDeviceReady() {
    if(window['cordova'] && window['cordova']['plugins'] && window['cordova']['plugins']['barcodeScanner']) {
      this.scanner = true;
      //this.readBarcode();
    }
  }

  submitCheckin(): void {
    const controls = this.formCheck.controls;
    const vehicle = this.vehicleEl.nativeElement;
    vehicle.setAttribute('value-state', '');
    /** check form */
    if (this.formCheck.invalid) {
      Object.keys(controls).forEach(controlName => {
        controls[controlName].markAsTouched()
        if (controls['vehicle'].invalid) {
          vehicle.setAttribute('value-state', 'Error');
          this.notification.show('Error',this.translate.instant('checkinout.insertcarplate'))
        }
      });
      return;
    }

    const params = {
      Canvas: "N",
      Data: this.formCheck.controls['date'].value,
      EmpId: this.checkIn['userCode'],
      Hour: this.formCheck.controls['hour'].value,
      Kms: this.formCheck.controls['kms'].value || 0,
      Rulers: this.checkIn['Rulers'],
      Status: "CHECKIN",
      Strap: this.checkIn['Strap'],
      Tires: "N",
      Trailer: "N",
      TrailerCode: this.checkIn['TrailerCode'],
      VehicleCode: this.checkIn['VehicleCode']
    };
    this.loading.show();
    this.apiService.saveCheckin(params)
      .subscribe(data => {
        this.isCheckedin = true;
        this.loading.hide();
        this.getDriverDetails();
        this.router.navigate(['/']);
        this.notification.show('Success',this.translate.instant('checkinout.checkinsuccess'));
      },error => {
        this.loading.hide();
        this.notification.show('Error',JSON.stringify(error['error']));
        console.log(error.error);
      },() => {
        this.loading.hide();
      });
  }

  submitCheckout(): void {
    const self = this;
    self.alertDialog.confirm(this.translate.instant('checkinout.checkoutconfirmation'), function(){
      const params = {
        Data: self.formCheck.controls['date'].value,
        EmpId: self.driver['empID'],
        Hour: self.formCheck.controls['hour'].value,
        Kms: self.formCheck.controls['kms'].value,
        Status: "CHECKOUT",
        TrailerCode: self.driver['TrailerCode'],
        VehicleCode: self.driver['VehiclePlate']
      };
      self.loading.show();
      self.apiService.saveCheckout(params)
        .subscribe(data => {
          self.isCheckedin = false;
          self.formCheck.reset();
          self.formCheck.patchValue({
            date: [moment().format('YYYY-MM-DD')],
            hour: [moment().format("HH:mm")]
          });
          self.loading.hide();
          self.getCheckin(false);
          //this.getCheckin();
          self.router.navigate(['/']);
          self.notification.show('Success',self.translate.instant('checkinout.checkoutsuccess'));
        },error => {
          self.loading.hide();
          self.notification.show('Error',JSON.stringify(error['error']));
          console.log(error.error);
        },() => {
          self.loading.hide();
        });
    },function(){});
  }

  closeBarcode(): void {
    //this.scanner = false;
    this.barcode = '';
    const barcode = this.barcodeEl.nativeElement;
    barcode.setAttribute('value-state', '');
    this.dialogBarcodeEl.nativeElement.close();
  }

  confirmBarcode(): void {
    const barcode = this.barcodeEl.nativeElement;
    barcode.setAttribute('value-state', '');
    if(!this.barcode) {
      barcode.setAttribute('value-state', 'Error');
      return;
    }
    const params = {
      NoCanvas: true,
      NoTires: true,
      NoTrailer: true,
      Rulers: 0,
      Strap: 0,
      VehiclePlate: this.barcode,
      YesCanvas: false,
      YesTires: false,
      YesTrailer: false,
      data: this.formCheck.controls['date'].value,
      hasChangeTrailer: false,
      hour: this.formCheck.controls['hour'].value,
      user:  localStorage.getItem('user'),
      userCode: localStorage.getItem('userid')
    }

    this.loading.show();
    this.apiService.getCheckinDetails(params)
      .subscribe(data => {
        //console.log(data);
        this.checkIn = data;
        this.formCheck.patchValue({
          vehicle: this.checkIn['VehiclePlate'],
          trailer: this.checkIn['TrailerPlate'],
          kms: this.checkIn['Kms']
        });
        //this.scanner = false;
        this.barcode = '';
        this.dialogBarcodeEl.nativeElement.close();
      },error => {
        this.loading.hide();
        this.notification.show('Error',JSON.stringify(error['error']));
        console.log(error.error);
      },() => {
        this.loading.hide();
      });
  }

  openBarcode(type): void {
    this.type = type;
    this.dialogBarcodeEl.nativeElement.show();
  }

  openBarCodeAlt(type): void {
    this.type = type;
    this.dlgScanBarcodeEl.nativeElement.show();
  }

  readBarcode(): void {
    const self = this;
    self.isScanning = true;
    window.history.pushState("BackLock", null, "");
    window.onpopstate = (evt) => {
      if(this.isScanning) {
        window.history.pushState("BackLock", null, "");
      }
      return;
    }
    window['cordova']['plugins']['barcodeScanner'].scan(
      function (result) {
        self.isScanning = false;
        if(result.cancelled) {
          self.dialogBarcodeEl.nativeElement.show();
        } else {
          self.barcode = result.text;
          setTimeout(function() {
            document.body.click();
            self.confirmBarcode();
          },100);
        }
      },
      function (error) {
        self.isScanning = false;
        self.notification.show('Error',"Scanning failed: " + error);
      },
      {
        preferFrontCamera : false, // iOS and Android
        showFlipCameraButton : false, // iOS and Android
        showTorchButton : false, // iOS and Android
        torchOn: false, // Android, launch with the torch switched on (if available)
        saveHistory: false, // Android, save scan history (default false)
        prompt : "Place a barcode inside the scan area", // Android
        resultDisplayDuration: 500, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
        //formats : "QR_CODE,PDF_417", // default: all but PDF_417 and RSS_EXPANDED
        orientation : "portrait", // Android only (portrait|landscape), default unset so it rotates with the device
        disableAnimations : true, // iOS
        disableSuccessBeep: false // iOS and Android
      }
    );
  }

  readBarCodeAltInit(): void {
    const self = this;
    setTimeout(function() {
      const dlgScan = self.dlgScanBarcodeEl.nativeElement;
      dlgScan.addEventListener("scan-success", function(event) {
        dlgScan.close();
        self.noScanner = false;
        self.barcode = event.detail.text;
        self.confirmBarcode();
      });
      dlgScan.addEventListener("scan-error", function(event) {
        self.noScanner = true;
      });
    }, 2000);
  }

  getCheckin(checkin): void {
    const params = {
      hasChangeTrailer: false,
      Strap: 0,
      Rulers: 0,
      YesCanvas: false,
      NoCanvas: true,
      YesTires: false,
      NoTires: true,
      YesTrailer: false,
      NoTrailer: true,
      data: this.formCheck.controls['date'].value,
      hour: this.formCheck.controls['hour'].value,
      user:  localStorage.getItem('user'),
      userCode: localStorage.getItem('userid')
    }

    this.loading.show();
    this.apiService.getCheckin(params)
      .subscribe(data => {
        if(data && data['U_Status'] == 'CHECKIN') {
          this.isCheckedin = true;
          this.getDriverDetails();
        } else {
          if(checkin) this.openBarcode('vehicle');
        }
      },error => {
        this.loading.hide();
        this.notification.show('Error',JSON.stringify(error['error']));
        console.log(error.error);
      },() => {
        this.loading.hide();
      });
  }

  getDriverDetails(): void {
    this.apiService.getDriverDetails()
      .subscribe(data => {
        //console.log(data);
        this.driver = data;
        this.formCheck.patchValue({
          vehicle: this.driver['VehiclePlate'],
          trailer: this.driver['TrailerPlate'],
          kms: this.driver['Kms']
        });
      },error => {
        this.loading.hide();
        this.notification.show('Error',JSON.stringify(error['error']));
        console.log(error.error);
      },() => {
        this.loading.hide();
      });
  }

}
