<ui5-page id="page" background-design="List">
  <header class="detail-page-header">
    <div class="detail-page-header-bar" style="height: 7rem;">
      <ui5-title class="title">{{'cards.drivercards' | translate}}<br>
        <span class="sub-title">{{'cards.available' | translate}}({{rows?.length}})</span>
      </ui5-title>
      <ui5-button
        design="Transparent"
        icon="synchronize"
        class="action-button" (click)="rerender()"></ui5-button>
    </div>
  </header>
  <div class="details-page">
    <div class="toolbar">
      <div class="btns-container">

      </div>
      <ui5-input #searchInput class="searchInput" (keyup)="updateFilter($event)" placeholder="{{'general.search' | translate}}..." >
        <ui5-icon id="searchIcon" slot="icon" name="search"></ui5-icon>
      </ui5-input>
    </div>

    <ngx-datatable
      #datatable
      style="width: 100%"
      class="material"
      [rows]="rows"
      [columnMode]="ColumnMode.force"
      [headerHeight]="50"
      [footerHeight]="50"
      rowHeight="auto"
      [limit]="10"
      [messages]="{emptyMessage: 'general.emptymessage' | translate,
        totalMessage: 'general.totalmessage' | translate,
        selectedMessage: 'general.selectedmessage' | translate}"
      (activate)="onActivate($event)"
      (select)="onSelect($event)">

      <!-- Row Detail Template -->
      <ngx-datatable-row-detail #myDetailRow (toggle)="onDetailToggle($event)">
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
          <div class="mobile-row" (click)="onSelectRow(row)">
            <div class="row-line-container">
              <ui5-label class="row-title">{{'cards.supplier' | translate}}:</ui5-label>
              <span class="row-line">{{ row['U_Type'] }}</span>
              <ui5-label class="row-title">{{'cards.number' | translate}}:</ui5-label>
              <span class="row-line">{{ row['U_Description'] }}</span>
              <ui5-label class="row-title">{{'cards.code' | translate}}:</ui5-label>
              <span class="row-line">{{ row['U_Pin'] }}</span>

              <br> <br>
            </div>
          </div>
        </ng-template>
      </ngx-datatable-row-detail>

      <ngx-datatable-column prop="U_Type" [flexGrow]="3">
        <ng-template let-row="row" let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span class="pointer" (click)="sort()">{{'cards.supplier' | translate}}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <b>{{value}}</b>
          <!--<span class="row-id">{{ (row['needCarga'] == 'Y' ? 'Carga' : 'Descarga' ) }}</span>-->
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column prop="U_Description" [flexGrow]="1">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span class="pointer mobile-hidden" (click)="sort()">{{'cards.number' | translate}}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <span class="mobile-hidden">{{ value }}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column prop="U_Pin" [flexGrow]="1">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span class="pointer mobile-hidden" (click)="sort()">{{'cards.code' | translate}}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <span class="mobile-hidden">{{ value }}</span>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</ui5-page>
