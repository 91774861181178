import {Component, ElementRef, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { AlertService } from '../../../services/alert.service';
import { addCustomCSS } from "@ui5/webcomponents-base/dist/Theming";
addCustomCSS("ui5-dialog",`.ui5-popup-header-root {
  justify-content: left;
  align-items: start;
}`)

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  el: ElementRef;
  type: string;
  message: any;

  constructor(private _element: ElementRef,
              private alertService: AlertService,
              private translate: TranslateService) {
    this.el = _element;
  }

  ngOnInit(): void {
    this.alertService.getMessage().subscribe(message => {
      this.message = message;
    });
  }

}
