import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpClient
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import { Observable, throwError } from 'rxjs';
import {retry, catchError, tap} from 'rxjs/operators';
import {LoadingComponent} from '../shared/dialogs/loading/loading.component';
import {AuthService} from '../auth/auth.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private returnUrl: string;

  constructor (private http: HttpClient,
               private router: Router,
               private authService: AuthService,
               private loading: LoadingComponent
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.returnUrl = event.url;
      }
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        tap(
          event => {
            if (event instanceof HttpResponse) {
              //console.log('all looks good');
              // http response status code
              //console.log(event.status);
            }
          },
          error => {
            if(error && error['error']['error'] == 'INVALID TOKEN') {
              this.loading.hide();
              this.authService.forceLogout();
              /*this.router.navigate(['/login'], {
                queryParams: {
                  returnUrl: this.returnUrl,
                  tokenExpired: true
                }
              });*/
            }
          }
        )
      )
  }
}
