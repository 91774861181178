<ui5-side-navigation #sidebar class="sidebar" [hidden]="!(isLoggedIn$ | async)">
  <ui5-side-navigation-item text="{{ 'menu.home' | translate }} " icon="bar-chart"
                            routerLink="/dashboard" class="menu-dashboard"></ui5-side-navigation-item>
  <ui5-side-navigation-item text="Check-in/out " icon="visits"
                            routerLink="/checkinout" class="menu-checkinout"></ui5-side-navigation-item>
  <ui5-side-navigation-item text="{{ 'menu.refulling' | translate }} "
                            routerLink="/refuelling" class="menu-refuelling" icon="mileage">
  </ui5-side-navigation-item>
  <ui5-side-navigation-item text="{{ 'menu.tasks' | translate }} " icon="activities"
                            routerLink="/tasks" class="menu-tasks"></ui5-side-navigation-item>
  <ui5-side-navigation-item text="{{ 'menu.maintenance' | translate }} "
                            routerLink="/maintenance" class="menu-maintenance" icon="add-equipment">
    <!--<ui5-side-navigation-sub-item text="Local"></ui5-side-navigation-sub-item>
    <ui5-side-navigation-sub-item text="Others"></ui5-side-navigation-sub-item>
  </ui5-side-navigation-item>
  <ui5-side-navigation-item text="Taskboard " icon="activities"
                            routerLink="/taskboard" class="menu-taskboard"></ui5-side-navigation-item>
  <ui5-side-navigation-item text="{{ 'menu.examples' | translate }} " expanded icon="customer-view" >
    <ui5-side-navigation-sub-item text="Reactive Form "
                                  routerLink="/forms" class="menu-forms"></ui5-side-navigation-sub-item>
    <ui5-side-navigation-sub-item text="{{ 'menu.todolist' | translate }} "
                                  routerLink="/todo" class="menu-todo"></ui5-side-navigation-sub-item>
   <ui5-side-navigation-sub-item text="From Other Teams"></ui5-side-navigation-sub-item>-->
  </ui5-side-navigation-item>
  <ui5-side-navigation-item text="{{ 'menu.cards' | translate }} "
                            routerLink="/cards" class="menu-cards" icon="credit-card">
  </ui5-side-navigation-item>
  <ui5-side-navigation-item slot="fixedItems" text="{{ 'menu.history' | translate }} " icon="history"
                            routerLink="/history" class="menu-history"></ui5-side-navigation-item>
  <ui5-side-navigation-item slot="fixedItems" text="{{ 'menu.about' | translate }} " icon="message-information"
                            routerLink="/links" class="menu-links"></ui5-side-navigation-item>
</ui5-side-navigation>
