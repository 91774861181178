<app-header></app-header>
<app-sidebar></app-sidebar>
<section class="app-content">
  <app-loading></app-loading>
  <app-notification></app-notification>
  <app-alert></app-alert>
  <app-messages></app-messages>
  <!--<ngx-chat rosterState="hidden"></ngx-chat>-->
  <router-outlet></router-outlet>
</section>
